import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {t} from "i18next";


const Dig = ({isActive}: { isActive?: boolean }) => {


    if (isActive) {
        return <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
            <path
                d="M18.2972 5.12363C17.374 4.20046 16.1462 3.69179 14.8408 3.69179H14.8094V3.6604C14.8094 2.35503 14.3007 1.1272 13.3776 0.204023L13.3757 0.202176C13.1052 -0.0683145 12.6676 -0.0664682 12.3971 0.202176C12.1276 0.472667 12.1276 0.9121 12.3981 1.18259C12.8514 1.63587 13.1652 2.19162 13.3176 2.79723C12.3861 2.26455 11.2626 2.14362 10.2046 2.49627C9.8418 2.61721 9.64793 3.00864 9.76886 3.37144C9.8898 3.73518 10.2858 3.93181 10.6468 3.80903C11.5247 3.51731 12.4729 3.73979 13.1329 4.38509C11.9023 5.7237 11.0345 7.61806 10.6653 9.80784C10.6016 10.1845 10.8564 10.5418 11.233 10.6055C11.2718 10.6119 11.3106 10.6156 11.3484 10.6156C11.6798 10.6156 11.9734 10.3756 12.0306 10.0377C12.3464 8.16458 13.0941 6.50194 14.1133 5.36643C14.7605 6.02835 14.9839 6.97922 14.6912 7.85993C14.5703 8.22274 14.7669 8.61417 15.1298 8.7351C15.2027 8.75911 15.2756 8.77111 15.3485 8.77111C15.6384 8.77111 15.9089 8.5874 16.0049 8.29752C16.3567 7.2414 16.2357 6.1179 15.7021 5.18456C16.3087 5.33781 16.8644 5.65077 17.3177 6.10497L17.3195 6.10682C17.59 6.37731 18.0276 6.37639 18.2981 6.10682C18.5677 5.83633 18.5677 5.39689 18.2972 5.1264V5.12363Z"
                fill="white"/>
            <path
                d="M18.4097 16.1492C17.7783 12.2054 13.948 9.23088 9.50107 9.23088C5.05413 9.23088 1.22386 12.2054 0.59241 16.1492C0.519479 16.6052 0.651493 17.0714 0.955219 17.4278C1.26633 17.7924 1.71869 18.0011 2.19597 18.0011H16.8071C17.2844 18.0011 17.7367 17.7915 18.0478 17.4278C18.3516 17.0714 18.4827 16.6052 18.4097 16.1492Z"
                fill="white"/>
            <path
                d="M3.96191 7.8461C5.87388 7.8461 7.42383 6.29615 7.42383 4.38419C7.42383 2.47222 5.87388 0.922272 3.96191 0.922272C2.04995 0.922272 0.5 2.47222 0.5 4.38419C0.5 6.29615 2.04995 7.8461 3.96191 7.8461Z"
                fill="white"/>
        </svg>
    } else {
        return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <g opacity="0.4">
                <path
                    d="M21.2971 8.12364C20.3739 7.20046 19.1461 6.69179 17.8407 6.69179H17.8093V6.6604C17.8093 5.35503 17.3006 4.1272 16.3774 3.20402L16.3756 3.20218C16.1051 2.93169 15.6675 2.93353 15.397 3.20218C15.1275 3.47267 15.1275 3.9121 15.398 4.18259C15.8512 4.63587 16.1651 5.19163 16.3174 5.79723C15.386 5.26456 14.2624 5.14362 13.2045 5.49627C12.8417 5.61721 12.6478 6.00864 12.7687 6.37145C12.8897 6.73518 13.2857 6.93182 13.6467 6.80903C14.5246 6.51731 15.4727 6.7398 16.1328 7.3851C14.9022 8.72371 14.0344 10.6181 13.6651 12.8078C13.6014 13.1845 13.8562 13.5418 14.2329 13.6055C14.2717 13.6119 14.3105 13.6156 14.3483 13.6156C14.6797 13.6156 14.9733 13.3756 15.0305 13.0377C15.3463 11.1646 16.094 9.50195 17.1132 8.36644C17.7604 9.02836 17.9838 9.97923 17.6911 10.8599C17.5702 11.2228 17.7668 11.6142 18.1296 11.7351C18.2026 11.7591 18.2755 11.7711 18.3484 11.7711C18.6383 11.7711 18.9088 11.5874 19.0048 11.2975C19.3565 10.2414 19.2356 9.1179 18.702 8.18457C19.3085 8.33782 19.8643 8.65078 20.3176 9.10498L20.3194 9.10683C20.5899 9.37732 21.0275 9.37639 21.298 9.10683C21.5676 8.83633 21.5676 8.3969 21.2971 8.12641V8.12364Z"
                    fill="white"/>
                <path
                    d="M21.4097 19.1493C20.7782 15.2054 16.9479 12.231 12.501 12.231C8.05404 12.231 4.22377 15.2054 3.59232 19.1493C3.51939 19.6053 3.6514 20.0715 3.95513 20.4279C4.26624 20.7925 4.7186 21.0012 5.19588 21.0012H19.807C20.2843 21.0012 20.7367 20.7916 21.0478 20.4279C21.3515 20.0715 21.4826 19.6053 21.4097 19.1493ZM19.9926 19.5287C19.9704 19.5545 19.9076 19.6164 19.8061 19.6164H5.19496C5.09341 19.6164 5.03063 19.5555 5.00847 19.5287C4.97985 19.4955 4.94754 19.4391 4.95955 19.3671C5.48483 16.088 8.72704 13.6157 12.501 13.6157C16.2749 13.6157 19.5171 16.0889 20.0424 19.3671C20.0544 19.4391 20.0212 19.4955 19.9926 19.5287ZM6.96192 10.8462C8.87105 10.8462 10.4238 9.29341 10.4238 7.38428C10.4238 5.47515 8.87105 3.92236 6.96192 3.92236C5.05279 3.92236 3.5 5.47515 3.5 7.38428C3.5 9.29341 5.05279 10.8462 6.96192 10.8462ZM6.96192 5.30713C8.10758 5.30713 9.03907 6.23862 9.03907 7.38428C9.03907 8.52995 8.10758 9.46143 6.96192 9.46143C5.81625 9.46143 4.88477 8.52995 4.88477 7.38428C4.88477 6.23862 5.81625 5.30713 6.96192 5.30713Z"
                    fill="white"/>
            </g>
        </svg>
    }
}


const Chest = ({isActive}: { isActive?: boolean }) => {

    if (isActive) {
        return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M1.19995 10.0799V8.70847C1.19995 5.23053 4.02948 2.3999 7.50852 2.3999H16.2857C19.7647 2.3999 22.5942 5.23053 22.5942 8.70847V10.0799H14.9142V9.80562C14.9142 8.74687 14.053 7.88562 12.9942 7.88562H10.8C9.74121 7.88562 8.87995 8.74687 8.87995 9.80562V10.0799H1.19995ZM18.2616 21.5999H19.5771C21.2415 21.5999 22.5942 20.2471 22.5942 18.5828V17.2673C20.3144 17.524 18.5173 19.3211 18.2616 21.5999ZM1.19995 17.2673V18.5828C1.19995 20.2471 2.55273 21.5999 4.21709 21.5999H5.53257C5.27584 19.32 3.47872 17.5229 1.19995 17.2673ZM14.9142 11.9999C14.9142 13.6643 13.5615 15.017 11.8971 15.017C10.2327 15.017 8.87995 13.6643 8.87995 11.9999V11.7256H1.19995V15.593C4.39264 15.8586 6.9413 18.4072 7.20681 21.5999H16.5885C16.854 18.4072 19.4026 15.8586 22.5953 15.593V11.7256H14.9153V11.9999H14.9142ZM13.2685 11.9999V9.80562C13.2685 9.65421 13.1456 9.53133 12.9942 9.53133H10.8C10.6485 9.53133 10.5257 9.65421 10.5257 9.80562V11.9999C10.5257 12.7558 11.1412 13.3713 11.8971 13.3713C12.653 13.3713 13.2685 12.7558 13.2685 11.9999Z"
                fill="white"/>
        </svg>
    } else {
        return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g opacity="0.4">
                <path
                    d="M16.2859 2.4H7.50877C4.02973 2.4 1.2002 5.23063 1.2002 8.70857V18.5829C1.2002 20.2472 2.55297 21.6 4.21734 21.6H19.5773C21.2417 21.6 22.5945 20.2472 22.5945 18.5829V8.70857C22.5945 5.23063 19.7649 2.4 16.2859 2.4ZM7.50877 4.04571H16.2859C18.8576 4.04571 20.9488 6.13797 20.9488 8.70857V10.08H14.0916C13.6374 10.08 13.2688 10.4486 13.2688 10.9029C13.2688 11.3571 13.6374 11.7257 14.0916 11.7257H20.9488V15.5931C18.5998 15.7325 16.7281 17.6053 16.5876 19.9543H7.20705C7.06772 17.6053 5.19489 15.7336 2.84591 15.5931V11.7257H9.70305C10.1573 11.7257 10.5259 11.3571 10.5259 10.9029C10.5259 10.4486 10.1573 10.08 9.70305 10.08H2.84591V8.70857C2.84591 6.13797 4.93706 4.04571 7.50877 4.04571ZM4.21734 19.9543C3.46141 19.9543 2.84591 19.3388 2.84591 18.5829V17.2235C4.29085 17.3541 5.44504 18.5083 5.5756 19.9543H4.21734ZM18.2191 19.9543C18.3496 18.5093 19.5038 17.3541 20.9488 17.2235V18.5829C20.9488 19.3388 20.3333 19.9543 19.5773 19.9543H18.2191Z"
                    fill="white"/>
                <path
                    d="M12.9947 7.88578H10.8004C9.74163 7.88578 8.88037 8.74704 8.88037 9.80578V12.0001C8.88037 13.6644 10.2331 15.0172 11.8975 15.0172C13.5619 15.0172 14.9147 13.6644 14.9147 12.0001V9.80578C14.9147 8.74704 14.0534 7.88578 12.9947 7.88578ZM13.2689 12.0001C13.2689 12.756 12.6534 13.3715 11.8975 13.3715C11.1416 13.3715 10.5261 12.756 10.5261 12.0001V9.80578C10.5261 9.65437 10.649 9.53149 10.8004 9.53149H12.9947C13.1461 9.53149 13.2689 9.65437 13.2689 9.80578V12.0001Z"
                    fill="white"/>
            </g>
        </svg>
    }
}


const Bag = ({isActive}: { isActive?: boolean }) => {


    if (isActive) {
        return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
                d="M18.6562 3.74769C19.1177 4.2 19.4038 4.83692 19.4038 5.53846C19.4038 6.94154 18.2685 8.07692 16.8654 8.07692H15.7115V6.46154C15.7115 6.08308 15.3977 5.76923 15.0192 5.76923C14.6408 5.76923 14.3269 6.08308 14.3269 6.46154V8.07692H10.1731V6.46154C10.1731 6.08308 9.85923 5.76923 9.48077 5.76923C9.10231 5.76923 8.78846 6.08308 8.78846 6.46154V8.07692H7.63462C6.93308 8.07692 6.29615 7.79077 5.84385 7.32923C5.38231 6.87692 5.09615 6.24 5.09615 5.53846C5.09615 4.13538 6.23154 3 7.63462 3H16.8654C17.5669 3 18.2038 3.28615 18.6562 3.74769ZM14.0962 15.4615H10.4038C10.2765 15.4615 10.1731 15.5649 10.1731 15.6923V16.6154C10.1731 17.2514 10.6909 17.7692 11.3269 17.7692H13.1731C13.8091 17.7692 14.3269 17.2514 14.3269 16.6154V15.6923C14.3269 15.5649 14.2235 15.4615 14.0962 15.4615ZM16.8654 9.46154C17.4377 9.46154 17.9915 9.33231 18.4808 9.10154V18.4615C18.4808 19.8646 17.3454 21 15.9423 21H8.55769C7.15462 21 6.01923 19.8646 6.01923 18.4615V9.10154C6.50846 9.33231 7.06231 9.46154 7.63462 9.46154H8.78846V10.1538C8.78846 10.5323 9.10231 10.8462 9.48077 10.8462C9.85923 10.8462 10.1731 10.5323 10.1731 10.1538V9.46154H14.3269V10.1538C14.3269 10.5323 14.6408 10.8462 15.0192 10.8462C15.3977 10.8462 15.7115 10.5323 15.7115 10.1538V9.46154H16.8654ZM15.7115 15.6923C15.7115 14.8062 14.9823 14.0769 14.0962 14.0769H10.4038C9.51769 14.0769 8.78846 14.8062 8.78846 15.6923V16.6154C8.78846 18.0185 9.92385 19.1538 11.3269 19.1538H13.1731C14.5762 19.1538 15.7115 18.0185 15.7115 16.6154V15.6923ZM19.8654 11.5846V18.4615C19.8654 18.6092 19.8562 18.7477 19.8469 18.8862C20.6777 18.4708 21.25 17.6123 21.25 16.6154V13.8462C21.25 12.8585 20.6869 12.0092 19.8654 11.5846ZM4.63462 11.5846C3.81308 12.0092 3.25 12.8585 3.25 13.8462V16.6154C3.25 17.6123 3.82231 18.4708 4.65308 18.8862C4.64385 18.7477 4.63462 18.6092 4.63462 18.4615V11.5846Z"
                fill="white"/>
        </svg>
    }

    return <svg
        opacity="0.4"
        xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
        <path
            d="M11.0962 10.1538H7.40385C6.51308 10.1538 5.78846 10.8785 5.78846 11.7692V12.6923C5.78846 14.0926 6.92662 15.2308 8.32692 15.2308H10.1731C11.5734 15.2308 12.7115 14.0926 12.7115 12.6923V11.7692C12.7115 10.8785 11.9869 10.1538 11.0962 10.1538ZM11.3269 12.6923C11.3269 13.3283 10.8091 13.8462 10.1731 13.8462H8.32692C7.69092 13.8462 7.17308 13.3283 7.17308 12.6923V11.7692C7.17308 11.6418 7.27646 11.5385 7.40385 11.5385H11.0962C11.2235 11.5385 11.3269 11.6418 11.3269 11.7692V12.6923ZM15.7115 8.30769H15.4808V5.40369C16.0402 4.93754 16.4038 4.24523 16.4038 3.46154V2.53846C16.4038 1.13815 15.2657 0 13.8654 0H4.63462C3.23431 0 2.09615 1.13815 2.09615 2.53846V3.46154C2.09615 4.24523 2.45985 4.93754 3.01923 5.40369V8.30769H2.78846C1.38815 8.30769 0.25 9.44585 0.25 10.8462V13.6154C0.25 15.0157 1.38815 16.1538 2.78846 16.1538H3.12723C3.43092 17.2163 4.39923 18 5.55769 18H12.9423C14.1008 18 15.0691 17.2163 15.3728 16.1538H15.7115C17.1118 16.1538 18.25 15.0157 18.25 13.6154V10.8462C18.25 9.44585 17.1118 8.30769 15.7115 8.30769ZM3.48077 2.53846C3.48077 1.90246 3.99862 1.38462 4.63462 1.38462H13.8654C14.5014 1.38462 15.0192 1.90246 15.0192 2.53846V3.46154C15.0192 4.09754 14.5014 4.61538 13.8654 4.61538H4.63462C3.99862 4.61538 3.48077 4.09754 3.48077 3.46154V2.53846ZM1.63462 13.6154V10.8462C1.63462 10.2102 2.15246 9.69231 2.78846 9.69231H3.01923V14.7692H2.78846C2.15246 14.7692 1.63462 14.2514 1.63462 13.6154ZM12.9423 16.6154H5.55769C4.92169 16.6154 4.40385 16.0975 4.40385 15.4615V6H14.0962V15.4615C14.0962 16.0975 13.5783 16.6154 12.9423 16.6154ZM16.8654 13.6154C16.8654 14.2514 16.3475 14.7692 15.7115 14.7692H15.4808V9.69231H15.7115C16.3475 9.69231 16.8654 10.2102 16.8654 10.8462V13.6154Z"
            fill="white"/>
        <path
            d="M7.17319 3.46154V7.15384C7.17319 7.536 6.86304 7.84615 6.48088 7.84615C6.09873 7.84615 5.78857 7.536 5.78857 7.15384V3.46154C5.78857 3.07938 6.09873 2.76923 6.48088 2.76923C6.86304 2.76923 7.17319 3.07938 7.17319 3.46154ZM12.0193 2.76923C11.6372 2.76923 11.327 3.07938 11.327 3.46154V7.15384C11.327 7.536 11.6372 7.84615 12.0193 7.84615C12.4015 7.84615 12.7117 7.536 12.7117 7.15384V3.46154C12.7117 3.07938 12.4015 2.76923 12.0193 2.76923Z"
            fill="white"/>
    </svg>
}


const Friends = ({isActive}: { isActive?: boolean }) => {


    if (isActive) {
        return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M20.8405 17.8437C19.8872 15.0893 17.7217 13.0169 15.0711 12.0924C16.2821 11.1636 17.0772 9.71749 17.0772 8.07686C17.0772 5.27764 14.7996 3 12.0004 3C9.20117 3 6.92353 5.27764 6.92353 8.07686C6.92353 9.71642 7.71765 11.1615 8.92648 12.0903C6.27689 13.0148 4.11255 15.0904 3.16024 17.8437C2.85242 18.7308 2.99243 19.6831 3.54287 20.4569C4.09972 21.2372 5.0018 21.7042 5.95732 21.7042H18.0424C18.9979 21.7042 19.9 21.2372 20.4568 20.4569C21.0073 19.682 21.1473 18.7308 20.8405 17.8437ZM14.4907 17.4097L12.9495 18.9627C12.6973 19.2171 12.3606 19.3571 12.0025 19.3571H12.0004C11.6402 19.3571 11.3035 19.215 11.0502 18.9584L9.51753 17.4033C9.18941 17.0688 9.06008 16.5996 9.17124 16.1464L10.0092 12.7454C10.4934 12.9528 11.0224 13.0639 11.5697 13.111L10.7638 16.3847L12.0025 17.6417L13.2466 16.3879L12.3926 13.1152C12.9409 13.0725 13.4711 12.9645 13.9574 12.7604L14.837 16.1304C14.9557 16.5867 14.8242 17.0763 14.4918 17.4097H14.4907Z"
                fill="white"/>
        </svg>
    }

    return <svg
        opacity="0.4"
        xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
        <path
            d="M9.00267 16.357H9.00054C8.64035 16.357 8.30367 16.2149 8.05036 15.9584L6.51768 14.4032C6.18956 14.0687 6.06023 13.5995 6.17139 13.1463L7.15363 9.16072C7.25944 8.73106 8.81563 9.11476 8.70982 9.54335L7.76392 13.3836L9.00267 14.6405L10.2468 13.3868L9.24636 9.55404C9.13521 9.12545 10.6861 8.72037 10.7972 9.14896L11.8361 13.1292C11.9547 13.5856 11.8233 14.0751 11.4909 14.4086L9.94964 15.9616C9.6974 16.216 9.36073 16.356 9.00267 16.356V16.357Z"
            fill="white"/>
        <path
            d="M17.8405 14.8437C16.8882 12.0915 14.726 10.0126 12.0786 9.08598C13.2853 8.15718 14.0772 6.71428 14.0772 5.07686C14.0772 2.27764 11.7996 0 9.00039 0C6.20117 0 3.92353 2.27764 3.92353 5.07686C3.92353 6.71428 4.71552 8.15718 5.92221 9.08598C3.27369 10.0116 1.11255 12.0904 0.160236 14.8437C-0.147582 15.7308 -0.00756734 16.6831 0.542871 17.4569C1.09972 18.2372 2.0018 18.7042 2.95732 18.7042H15.0424C15.9979 18.7042 16.9 18.2372 17.4568 17.4569C18.0073 16.682 18.1473 15.7308 17.8405 14.8437ZM5.52674 5.07686C5.52674 3.16155 7.08507 1.60322 9.00039 1.60322C10.9157 1.60322 12.474 3.16155 12.474 5.07686C12.474 6.99217 10.9157 8.5505 9.00039 8.5505C7.08507 8.5505 5.52674 6.99217 5.52674 5.07686ZM16.1507 16.5271C15.891 16.8915 15.487 17.101 15.0424 17.101H2.95839C2.51376 17.101 2.11082 16.8915 1.85003 16.5271C1.6042 16.1808 1.54007 15.7586 1.67581 15.3674C2.75424 12.2486 5.69882 10.1537 9.00039 10.1537C12.3019 10.1537 15.2465 12.2486 16.325 15.3674C16.4607 15.7586 16.3966 16.1808 16.1507 16.5271Z"
            fill="white"/>
    </svg>
}

const Cards = ({isActive}: { isActive?: boolean }) => {

    if (isActive) {
        return <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
                d="M17.7825 2.4H7.71794C6.19115 2.4 4.9502 3.64095 4.9502 5.16774V19.2581C4.9502 20.7849 6.19115 22.0258 7.71794 22.0258H17.7825C19.3092 22.0258 20.5502 20.7849 20.5502 19.2581V5.16774C20.5502 3.64095 19.3092 2.4 17.7825 2.4ZM16.1057 13.1187L14.2599 16.1944C13.9388 16.7299 13.3742 17.0499 12.7502 17.0499C12.1262 17.0499 11.5606 16.7299 11.2405 16.1944L9.39469 13.1187C9.05954 12.5591 9.05954 11.8657 9.39469 11.3061L11.2405 8.23037C11.5616 7.69494 12.1262 7.37489 12.7502 7.37489C13.3742 7.37489 13.9398 7.69494 14.2599 8.23037L16.1057 11.3061C16.4409 11.8657 16.4409 12.5591 16.1057 13.1187ZM14.8114 12.0831C14.8587 12.1636 14.8587 12.2622 14.8114 12.3427L12.9656 15.4185C12.8378 15.6308 12.6616 15.6308 12.5338 15.4185L10.688 12.3427C10.6407 12.2622 10.6407 12.1636 10.688 12.0831L12.5338 9.00735C12.5972 8.90168 12.6969 8.88457 12.7492 8.88457C12.8015 8.88457 12.9012 8.90067 12.9646 9.00735L14.8104 12.0831H14.8114Z"
                fill="white"/>
        </svg>
    }


    return (
        <svg opacity="0.4" xmlns="http://www.w3.org/2000/svg" width="17" height="21" viewBox="0 0 17 21" fill="none">
            <path
                d="M12.1059 9.30718L10.2601 6.23146C9.93904 5.69603 9.37442 5.37598 8.75043 5.37598C8.12643 5.37598 7.5608 5.69603 7.24075 6.23146L5.39492 9.30718C5.05977 9.86676 5.05977 10.5602 5.39492 11.1198L7.24075 14.1955C7.56181 14.7309 8.12643 15.051 8.75043 15.051C9.37442 15.051 9.94005 14.7309 10.2601 14.1955L12.1059 11.1198C12.4411 10.5602 12.4411 9.86676 12.1059 9.30718ZM10.8116 10.3428L8.96581 13.4185C8.83799 13.6309 8.66186 13.6309 8.53404 13.4185L6.68821 10.3428C6.6409 10.2623 6.6409 10.1637 6.68821 10.0832L8.53404 7.00743C8.59744 6.90176 8.69708 6.88465 8.74942 6.88465C8.80175 6.88465 8.90139 6.90075 8.9648 7.00743L10.8106 10.0832C10.8579 10.1637 10.8579 10.2623 10.8106 10.3428H10.8116Z"
                fill="white"/>
            <path
                d="M13.7825 0.399902H3.71794C2.19115 0.399902 0.950195 1.64086 0.950195 3.16764V17.258C0.950195 18.7848 2.19115 20.0257 3.71794 20.0257H13.7825C15.3092 20.0257 16.5502 18.7848 16.5502 17.258V3.16764C16.5502 1.64086 15.3092 0.399902 13.7825 0.399902ZM15.0405 17.258C15.0405 17.9514 14.4759 18.516 13.7825 18.516H3.71794C3.02449 18.516 2.45987 17.9514 2.45987 17.258V3.16764C2.45987 2.4742 3.02449 1.90958 3.71794 1.90958H13.7825C14.4759 1.90958 15.0405 2.4742 15.0405 3.16764V17.258Z"
                fill="white"/>
        </svg>
    )
}


const navItems = [
    {
        name: 'chests',
        path: '/',
        src: (isActive: boolean) => <Chest isActive={isActive}/>,
    },
    {
        name: 'cards',
        path: '/cards',
        src: (isActive: boolean) => <Cards isActive={isActive}/>,
    },
    {
        name: 'dig',
        path: '/digger',
        src: (isActive: boolean) => <Dig isActive={isActive}/>,
    },
    {
        name: 'storage',
        path: '/bag',
        src: (isActive: boolean) => <Bag isActive={isActive}/>,
    },
    {
        name: 'friends',
        path: '/referral',
        src: (isActive: boolean) => <Friends isActive={isActive}/>,
    },
];


interface Props {
    isAbsolute?: boolean
}

export const Menu = (props: Props) => {

    const location = useLocation();
    return <Container style={{
        position: props.isAbsolute ? 'absolute' : 'relative',
        bottom: '0',
        zIndex: 1000,
        marginBottom: '6px',
        left: '50%',
        transform: 'translateX(-50%)'
    }}>
        <Wrapper>
            {navItems.map((item) => (
                <>
                    <MenuItem to={item.path} isSelected={location?.pathname === item.path}>
                        <div style={{height: '24px', width: '24px' , display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {item.src(location?.pathname === item.path)}
                        </div>
                        {t(`menu.${item.name}`)}
                    </MenuItem>
                </>
            ))}
        </Wrapper>
    </Container>
}


const Container = styled.div`
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #384E63;
    background: rgba(49, 74, 95, 0.50);
    -webkit-backdrop-filter: blur(5px); /* Add this for iOS compatibility */
    backdrop-filter: blur(5px);
    width: 90%;
    box-sizing: border-box;



`


const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`


const MenuItem = styled(Link)<{ isSelected?: boolean }>`
    display: flex;
    //padding: 6px 2px;
    height: 54px;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: ${props => props.isSelected ? 'rgba(87, 113, 137, 0.40)' : 'transparent'};
    backdrop-filter: ${props => props.isSelected ? 'blur(5px)' : 'none'};
    color: #FFF;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 140% */
    text-decoration: none;
    width: 64px;
`

