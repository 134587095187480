import React from "react";
import styled from "styled-components";
import {Profile} from "./icons/profile";
import {Referral} from "../api/requests/model";
import {t} from "i18next";

interface Props {
    referral: Referral
    type: "new_referral" | "referral_levelup"
}

export const ReferralItem = (props: Props) => {
    const {referral} = props
    return (
        <Container>
            <Row
                style={{
                    gap: 24
                }}
            >
                <Profile/>
                <Column>
                    <Name>
                        {referral?.user?.username || t('referral.user')}
                    </Name>
                    {props?.type === "referral_levelup" && (
                        <Level>
                            Level {referral?.ref_level}
                        </Level>
                    )}
                </Column>
            </Row>

            <Row>
                <img
                    src="/images/icon-coin.png"
                    style={{
                        width: 38,
                        height: 38,
                    }}
                />
                + {referral?.coin_cnt}
            </Row>

        </Container>
    )

}


const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;
`

const Row = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #FFD600;
`


const Level = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    text-align: left;
    color: rgba(204, 208, 212, 1);
`

const Name = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 14.4px;
    color: white;
`

const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 8px 16px 8px 8px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(2px);
`