import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {ChestList} from "../components/chest-list";
import {Header} from "../components/header";
import {useChestStore} from "../store/chest-store";
import {Menu} from "../components/menu";
import {BagList} from "../components/bag-list";
import {useBagList} from "../api/requests/bag";
import {useSendClicksOnEveryPage} from "../hooks/useSendClicksOnEveryPage";
import {useMeStore} from "../store/me-store";
import {Bag as IBag, Item} from "../api/requests/model";
import {useNavigate} from "react-router-dom";
import {MenuSwitch} from "../components/menu-switch";
import {ItemInfoModal} from "../components/item-info-modal";
import {UnlockPosition} from "../components/unlock-position";
import {post} from "../api/apiClient";
import {CraftModal} from "../components/craft-modal";
import {t} from "i18next";
import {GameAnalytics} from "gameanalytics";
import {Event} from "../constant";


const calculateAlignByPosition = (position: { top: number; left: number }) => {
    if (position.left < 100) {
        return 'left'
    } else if (position.left > 200) {
        return 'right'
    } else {
        return 'center'
    }

}

export const getTextByType = (type: string) => {
    return t(`modifiers.${type}`) || ''
}


export const getItems = (data: IBag) => {
    if (data['item']) {
        return data['item']
    } else if (data['item_artefact']) {
        return data['item_artefact']
    } else if (data['item_super_artefact']) {
        return data['item_super_artefact']
    }
}


export const getItemByID = (id?: number, data?: IBag[]) => {

    if (!data) return null

    for (let i = 0; i < data.length; i++) {
        if (data[i]['item']?.id === id) {
            return {data: data[i]['item'], type: 'item'}
        } else if (data[i]['item_artefact']?.id === id) {
            return {data: data[i]['item_artefact'], type: 'artefact'}
        } else if (data[i]['item_super_artefact']?.id === id) {
            return {data: data[i]['item_super_artefact'], type: 'super_artefact'}
        }
    }
    return null
}


export const Bag = () => {
    const [activeTab, setActiveTab] = useState({label: t('storage.chests'), value: 'chests'})
    const {data, mutate} = useBagList();
    const {setActiveChest, chests, activeChestId} = useChestStore()
    const {user, setUser} = useMeStore()
    const [selectedItem, setSelectedItem] = useState<number | undefined>();
    const [itemToCraft, setItemToCraft] = useState<{
        data?: Item,
        type?: 'item' | 'artefact' | 'super_artefact'
    } | null>(null);
    const [itemPosition, setItemPosition] = useState<{ top: number; left: number } | null>(null);
    const [isUnlockPositionLoading, setIsUnlockPositionLoading] = useState(false);
    const [isUnlockPositionVisible, setIsUnlockPositionVisible] = useState(false);

    const [isCraftModalLoading, setIsCraftModalLoading] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const infoRef = useRef<HTMLDivElement>(null);

    const handleSelect = (id: number, element: HTMLDivElement | null) => {
        setSelectedItem(id);
        if (element && containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();
            setItemPosition({
                top: elementRect.top - containerRect.top,
                left: elementRect.left - containerRect.left,
            });

        }
    };

    useEffect(() => {
        const handleAnyClick = () => {
            // eslint-disable-next-line no-restricted-globals
            if (infoRef.current && !infoRef.current.contains(event?.target as Node)) {
                setSelectedItem(undefined);
            }
        };
        document.addEventListener("mousedown", handleAnyClick);
        document.addEventListener("touchstart", handleAnyClick);

        return () => {
            document.removeEventListener("mousedown", handleAnyClick);
            document.removeEventListener("touchstart", handleAnyClick);
        };
    }, []);


    useSendClicksOnEveryPage()

    const navigate = useNavigate();


    const onUnlockPosition = async () => {
        try {
            setIsUnlockPositionLoading(true)
            const resp = await post({
                input: '/api/user/item-slot/buy',
                init: {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            })

            const result = await resp.json() as { status: boolean, result: { coin_cnt: number, item_slot_cnt: number } }

            if (result.status) {
                setUser((prev) => {
                    if (!prev) return prev
                    return {
                        ...prev,
                        coin_cnt: result.result.coin_cnt,
                        item_slot_cnt: result.result.item_slot_cnt
                    }
                })
                GameAnalytics.addDesignEvent(`${Event.ITEM_SLOT_BUY}:${result.result.item_slot_cnt - 1}`)
            }

        } catch (e) {
            console.log(e)
        } finally {
            setIsUnlockPositionLoading(false)
            setIsUnlockPositionVisible(false)
        }

    }


    const onCraft = async (itemId: any, to: 'artefact' | 'super_artefact') => {
        setIsCraftModalLoading(true)
        try {
            const resp = await post({
                input: `/api/user-item/upgrade`,
                data: {
                    item_id: itemId,
                    to: to
                },
                init: {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            })

            const result = await resp.json() as { status: boolean, result: { coin_cnt: number, item_slot_cnt: number } }

            if (result.status) {
                setUser((prev) => {
                    if (!prev) return prev
                    return {
                        ...prev,
                        coin_cnt: result.result.coin_cnt,
                    }
                })
                if (to === 'artefact') {
                    GameAnalytics.addDesignEvent(`${Event.ITEM_CRAFT_ARTIFACT}:${getItemByID(itemId)?.data?.title}`)
                } else {
                    GameAnalytics.addDesignEvent(`${Event.ITEM_CRAFT_SUPER_ARTIFACT}:${getItemByID(itemId)?.data?.title}`)
                }
                await mutate()
                //@ts-ignore
                graspil.push({event: 'Апгрейднул вещь'})

            }

        } catch (e) {
            console.log(e)
        } finally {
            setIsCraftModalLoading(false)
            setItemToCraft(null)
        }
    }

    useEffect(() => {
        //@ts-ignore
        graspil.push({event: 'Зашел в инвентарь'})
    }, []);

    return (
        <Container ref={containerRef}>
            <Header user={user || null}/>
            <Title>
                {t('storage.title')}
            </Title>
            <FixedBottomInfo>

                <Wrapper>
                    <MenuSwitch
                        activeTab={activeTab.value}
                        onChange={(tab) => setActiveTab({label: tab, value: tab})}
                        tabs={[
                            {
                                label: t('storage.chests'),
                                value: 'chests'
                            }, {
                                label: t('storage.items'),
                                value: 'items'
                            }]
                        }
                    />

                    {activeTab.value === 'items' &&
                        <BagList
                            itemSlotCnt={user?.item_slot_cnt || 0}
                            onUnlock={() => setIsUnlockPositionVisible(true)}
                            selectedID={selectedItem}
                            onSelect={(item, element) => handleSelect(item, element)}
                            data={data?.result || []}
                        />
                    }
                    {activeTab.value === 'chests' &&
                        <ChestList
                            selectedChestId={activeChestId}
                            onSelect={(e) => {
                                setActiveChest(e)
                                navigate(`/`)
                            }}
                            data={chests || []}
                        />
                    }
                </Wrapper>
            </FixedBottomInfo>
            {selectedItem && itemPosition && (
                <InfoWrapper
                    ref={infoRef}
                    style={{top: itemPosition.top - 65}}
                >
                    <ItemInfoModal
                        isCraftable={(data?.result?.find((e) => e.item?.id === selectedItem)?.item?.cnt || 0) >= 3 || (data?.result?.find((e) => e.item_artefact?.id === selectedItem)?.item_artefact?.cnt || 0) >= 3 || (data?.result?.find((e) => e.item_super_artefact?.id === selectedItem)?.item_super_artefact?.cnt || 0) >= 3}
                        //@ts-ignore
                        onClick={() => setItemToCraft(getItemByID(selectedItem, data?.result || []))}
                        align={calculateAlignByPosition(itemPosition)}
                        content={`${getItemByID(selectedItem, data?.result)?.data?.title} <br/>${getTextByType(getItemByID(selectedItem, data?.result)?.data?.modifier?.name || '')} + ${getItemByID(selectedItem, data?.result)?.data?.modifier.name != 'tap_to_coin' ? ` ${Math.round((getItemByID(selectedItem, data?.result)?.data?.modifier?.value || 0) * 100)} % ` : getItemByID(selectedItem, data?.result)?.data?.modifier?.value}`}
                    />
                </InfoWrapper>
            )}
            {isUnlockPositionVisible &&
                <UnlockPosition
                    onClose={() => setIsUnlockPositionVisible(false)}
                    loading={isUnlockPositionLoading}
                    onUnlock={onUnlockPosition}
                />}
            {itemToCraft &&
                <CraftModal
                    loading={isCraftModalLoading}
                    onCraft={onCraft}
                    onClose={() => setItemToCraft(null)}
                    item={itemToCraft}
                />
            }
            <Menu isAbsolute/>
        </Container>
    );
}


const InfoWrapper = styled.div`
    position: absolute;
    z-index: 10;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 10px 10px 10px;
    height: 100%;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    gap: 16px;
`


const FixedBottomInfo = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 80%;
`

const Container = styled.div<{ isBlur?: boolean }>`
    display: flex;
    height: 100vh;
    scrollbar-width: none;
    width: 100%;
    background-image: url('/bg/main-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
`;


const Title = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
`


