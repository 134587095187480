import * as THREE from "three";

export function removeObjectFromScene(scene: THREE.Scene, object: THREE.Object3D) {
    object.traverse((child: THREE.Object3D) => {
        if (child instanceof THREE.Mesh) {
            if (child.material) {
                const materials = Array.isArray(child.material) ? child.material : [child.material];
                materials.forEach((material) => {
                    if (material.map) {
                        material.map.dispose();
                        material.map = null;
                    }
                    material.dispose();
                });
            }
            if (child.geometry) child.geometry.dispose();
        }
    });

    object.parent?.remove(object);
    scene.remove(object);
}

export function calcOrthographicCameraFrustum(frustumSize: number, aspectRatio: number) {
    return {
        left: (-frustumSize * aspectRatio) / 2,
        right: (frustumSize * aspectRatio) / 2,
        top: frustumSize / 2,
        bottom: -frustumSize / 2,
        near: -10,
    }
}

export function setEffectCleaner(scene: THREE.Scene, effect: THREE.Object3D, timeout: number = 50) {
    const interval = setInterval(() => {
        let isMarkForDestroy = true;
        effect.traverse(child => {
            if (child.type != "ParticleEmitter") return;
            const c = child as any;
            if (c.system.markForDestroy === false) {
                isMarkForDestroy = false;
            }
        });

        if (isMarkForDestroy) {
            removeObjectFromScene(scene, effect);
            clearInterval(interval);
        }
    }, timeout);
}
