import styled, {keyframes} from "styled-components";
import {Ton} from "./components/icons/ton";
import React, {useState} from "react";
import {post} from "./api/apiClient";
import {GameAnalytics} from "gameanalytics";
import {Event} from "./constant";

interface Props {
    balance: number
    onClose: () => void
}

export const Withdraw = ({balance, onClose}: Props) => {
    const [value, setValue] = useState('0')
    const [loading, setLoading] = useState(false)

    const onWithdraw = async () => {
        setLoading(true)
        try {
            const res = await post({
                input: '/api/user/ton/withdraw',
                data: {
                    amount: value
                }
            })
            if ((await res.json()).result) {
                GameAnalytics.addDesignEvent(Event.TON_WITHDRAW, Number(value))
                onClose()

            }

        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }


    }


    return (
        <Container>
            <Header>
                <Balance>
                    <Ton style={{width: 24, height: 24}}/>
                    {balance}
                </Balance>
                <svg
                    onClick={onClose}
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M14 2L2 14" stroke="#F7F7F7" stroke-width="2" stroke-miterlimit="10"
                          stroke-linecap="square"/>
                    <path d="M14 14L2 2" stroke="#F7F7F7" stroke-width="2" stroke-miterlimit="10"
                          stroke-linecap="square"/>
                </svg>
            </Header>
            <Ton style={{width: 80, height: 80, marginBottom: 24}}/>
            <EnterText>
                Введите количество:
            </EnterText>
            <Input
                defaultValue={0}
                type={'number'}
                // @ts-ignore
                onChange={(e) => setValue(Number(e.target.value))}
            />
            <Warning>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g opacity="0.4" clip-path="url(#clip0_596_242906)">
                        <path
                            d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM9 11.5C9 11.776 8.776 12 8.5 12H7.5C7.224 12 7 11.776 7 11.5V7.5C7 7.224 7.224 7 7.5 7H8.5C8.776 7 9 7.224 9 7.5V11.5ZM8 6C7.448 6 7 5.552 7 5C7 4.448 7.448 4 8 4C8.552 4 9 4.448 9 5C9 5.552 8.552 6 8 6Z"
                            fill="#F7F7F7"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_596_242906">
                            <rect width="16" height="16" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <span>
                    Минимальная сумма вывода <TSpan>TON</TSpan>: 5
                </span>
            </Warning>
            <Button onClick={onWithdraw}>
                {loading ? <Loader/> : 'Вывести TON'}
            </Button>
        </Container>
    )
}

const Warning = styled.div`
    display: flex;
    height: 40px;
    padding: 8px 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #1C3246;
    background: #112232;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
`

const TSpan = styled.span`
    color: #4DA3E1;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`


const Input = styled.input`
    background: #08121B;
    box-sizing: border-box;
    border-radius: 16px;
    color: #FFF;
    padding: 12px;
    width: 100%;
    margin-top: 16px;
    border: 1px solid #08121B;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */

    &:focus {
        outline: none;
        border: 1px solid #08121B;
    }
`

const flash = keyframes`
    0% {
        background-color: #FFF2;
        box-shadow: 32px 0 #FFF2, -32px 0 #FFF;
    }
    50% {
        background-color: #FFF;
        box-shadow: 32px 0 #FFF2, -32px 0 #FFF2;
    }
    100% {
        background-color: #FFF2;
        box-shadow: 32px 0 #FFF, -32px 0 #FFF2;
    }
`;

const Loader = styled.span`
    width: 10px;
    height: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 32px 0 #fff, -32px 0 #fff;
    position: relative;
    animation: ${flash} 0.5s ease-out infinite alternate;
`;


const Button = styled.div`
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #84CCFF;
    background: linear-gradient(180deg, #68B5EC 0%, #2588CF 95.97%);
    box-shadow: 0px 3px 0px 0px #1D689F;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
`

const Container = styled.div`
    background: #08121B;
    height: 100vh;
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const Balance = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    display: flex;
    gap: 7px;
    align-items: center;
`

const EnterText = styled.div`
    color: #FFF;
    text-align: center;
    opacity: 0.6;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
`