import React, {useEffect} from "react";
import styled from "styled-components";
import {ChestList} from "../components/chest-list";
import {Header} from "../components/header";
import {useChestList} from "../api/requests/chest";
import {Loader} from "../components/loader";
import {useChestStore} from "../store/chest-store";
import {Menu} from "../components/menu";
import {useNavigate} from "react-router-dom";
import {useSendClicksOnEveryPage} from "../hooks/useSendClicksOnEveryPage";
import {useMeStore} from "../store/me-store";
import {FixedTopInfo} from "../components/fixed-top-info";

export const Chests = () => {

    const {data, isLoading: isLoading1} = useChestList();
    const {user} = useMeStore()
    const {setActiveChest, activeChestId} = useChestStore()

    const navigate = useNavigate();
    useSendClicksOnEveryPage()
    useEffect(() => {
        //@ts-ignore
        graspil.push({event: 'Зашел в сундуки'})

    }, []);

    if (isLoading1 || !user) {
        return <Loader/>
    }


    return (
        <Container>
            <Header user={user || null}/>
            <FixedTopInfo user={user}/>
            <FixedBottomInfo>
                <ChestList
                    selectedChestId={activeChestId}
                    onSelect={(e) => {
                        setActiveChest(e)
                        navigate(`/`)
                    }}
                    data={data?.result || []}
                />
            </FixedBottomInfo>
            <Menu isAbsolute/>

        </Container>
    );
}


const FixedBottomInfo = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 70%;
`

const Container = styled.div`
    display: flex;
    height: 100vh;
    scrollbar-width: none;
    width: 100%;
    background-image: url('/bg/main-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
`;
