import styled, {css, keyframes} from 'styled-components';
import React from "react";
import {Coin} from "./icons/coin";

export const Loader = () => {
    return (
        <Container>
            <AnimatedCoin
                isLoading={true}
                style={{
                    width: 48,
                    height: 48,
                }}
            />
        </Container>
    )
}

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const AnimatedCoin = styled(Coin)<{ isLoading: boolean }>`
  ${(props) =>
          props.isLoading &&
          css`
            animation: ${rotate} 1s linear infinite;
          `}
`

const Container = styled.div`
    display: flex;
    height: 100vh;
    scrollbar-width: none;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(180deg, #000 0%, #0A0F1E 100%);
    position: fixed;
    z-index: 10000000000;
`;
