import styled, {keyframes} from "styled-components";
import {Button} from "./button";
import {buyCard, CardResponse} from "../api/requests/cards";
import {useMeStore} from "../store/me-store";
import {useState} from "react";
import {t} from "i18next";
import {GameAnalytics} from "gameanalytics";
import {Event} from "../constant";

interface Props {
    cardData: CardResponse | null
    mutate: () => Promise<any>
    setCardData: (cardData: CardResponse | null) => void

}

export const BuyCardModal = (props: Props) => {
    const {cardData} = props
    const {setUser, user} = useMeStore()
    const [loading, setLoading] = useState(false)

    if (!cardData) return null

    const isBought = cardData?.cur_level?.level > 0


    const onBuy = async () => {
        try {
            setLoading(true)
            const resp = await buyCard(cardData.card.id)
            if (resp.result) {
                setUser((prev: any) => {
                        return {
                            ...prev,
                            coin_cnt:  resp.result.coin_cnt,
                            coin_reward:   resp.result.coin_reward
                        }
                    }
                )
            }
            await props.mutate()
            setLoading(false)
            //@ts-ignore
            graspil.push({event: 'Прокачал карточку'})
            GameAnalytics.addDesignEvent(Event.CARDS_BUY, cardData?.card?.id)
            props.setCardData(null)
        } catch (e) {
            setLoading(false)
            console.log(e)
        } finally {
            setLoading(false)
        }


    }

    return (
        <Overlay onClick={() => props.setCardData(null)}>
            <AnimatedContainer onClick={(e) => e.stopPropagation()}>
                <ImgContainer>
                    <Img

                        isBought={isBought}
                        src={cardData.card.art_url} alt=""/>
                    <BlurOverlay/>
                    <svg
                        onClick={() => props.setCardData(null)}
                        style={{
                            position: 'absolute',
                            top: 16,
                            right: 18
                        }}
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M14 2L2 14" stroke="#F7F7F7" stroke-width="2" stroke-miterlimit="10"
                              stroke-linecap="square"/>
                        <path d="M14 14L2 2" stroke="#F7F7F7" stroke-width="2" stroke-miterlimit="10"
                              stroke-linecap="square"/>
                    </svg>
                </ImgContainer>
                <Column>
                    <UpColumn>
                        <Title>{cardData.card.title}</Title>
                        <InComePerHour>
                            <InComePerHourTitle>{t("cards.profitPerHour")}:</InComePerHourTitle>
                            <InComePerHourValue>
                                <img src="/images/icon-coin.png" style={{width: 16, height: 16}} alt=""/>
                                {!isBought ? cardData.next_level.coin_reward : '+' + (cardData.next_level.coin_reward - cardData.cur_level.coin_reward)}
                            </InComePerHourValue>
                        </InComePerHour>
                        <Price>
                            <img src="/images/icon-coin.png" style={{width: 38, height: 38}} alt=""/>
                            {cardData.next_level.price}
                        </Price>
                    </UpColumn>
                    <Row>
                        <Level>
                            lvl
                            {cardData.next_level.level}
                        </Level>
                        <Button
                            loading={loading}
                            onClick={onBuy}>
                            {(user?.coin_cnt || 0) < cardData.next_level.price ? t('no_money') : t('buy')}
                        </Button>
                    </Row>
                </Column>
            </AnimatedContainer>
        </Overlay>
    )
}


const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
`

const Container = styled.div`
    position: absolute;
    z-index: 99999;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px 20px 0 0;
    height: 68%;
    background: #021127;
    width: 100%;
    box-sizing: border-box;
`

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;


const AnimatedContainer = styled(Container)`
    animation: ${fadeIn} 0.5s ease-in-out;
`;


const ImgContainer = styled.div`
    position: relative;
    width: 100%;
    min-height: 197px;
    border-radius: 30px 30px 0px 0px;

`;

const Img = styled.img<{ isBought?: boolean }>`
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: ${props => props.isBought ? 'none' : 'grayscale(100%)'};
    image-rendering: ${props => props.isBought ? 'auto' : 'pixelated'};
    border-radius: 30px 30px 0px 0px;

`;

const BlurOverlay = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;  
    background: linear-gradient(180deg, rgba(11, 24, 37, 0) 0%, #0B1825 89.5%);

`;

const Title = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
`


const InComePerHourTitle = styled.div`
    color: #6e747d;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
`


const InComePerHourValue = styled.div`
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;

    display: flex;
    align-items: center;
    gap: 4px;
`

const InComePerHour = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    justify-content: center;

`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: 100%;
    padding: 24px 24px 32px 24px;
    box-sizing: border-box;
`

const UpColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`


const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    width: 100%;
`

const Level = styled.div`
    display: flex;
    width: 85px;
    padding: 14px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #102234;
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    opacity: 0.4;
`


const Price = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    color: #fff;
    box-sizing: border-box;

`