import styled, {keyframes} from "styled-components";
import {Button} from "./button";
import React from "react";
import {ColdDown} from "./cold-down";
import {t} from "i18next";
import {ModalHead} from "./modal-head";

interface Props {
    coldown_sec: number;
    onClose: () => void;
    mutate: () => Promise<any>
}


export const RegretWindow = (props: Props) => {
    return (

        <Absolute>
            <AnimatedRelative>

                <ModalHead text={t("regret.mainText")} variant={'gray'}/>

                <img
                    src={'/images/deck-bg-dark.png'}
                    style={{
                        width: '319px',
                        height: '513px'
                    }}
                />

                <Column2
                    style={{
                        overflow: 'hidden',

                    }}
                >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '0px',
                    }}>
                        <Title>{t("regret.title")}</Title>
                        <Text>
                            {t("regret.text")}
                        </Text>
                    </div>
                    <div

                        style={{
                            position: 'relative',
                            width: '100%',
                            height: '222px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <ColdDownContainer>
                            <ColdDown
                                isGray
                                mutate={props.mutate}
                                scale={1.2}
                                coldDown={props.coldown_sec}/>
                        </ColdDownContainer>
                    </div>
                    <Button
                        style={{zIndex: 100000}}
                        onClick={() => {
                            props.onClose()
                        }}
                        width={'270px'}>
                        {t("continue")}
                    </Button>
                </Column2>
            </AnimatedRelative>
        </Absolute>

    )
}


const ColdDownContainer = styled.div`
`

const Title = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
`

const Text = styled.div`
    color: #EEE;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
`

const Column2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    width: 98%;
    height: calc(100% - 31px);
    position: absolute;
    bottom: 0;
`

const Relative = styled.div`
    position: relative;
`


const Absolute = styled.div`
    z-index: 10000;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
`
const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;


const AnimatedRelative = styled(Relative)`
    animation: ${fadeIn} 0.3s ease-in-out;
`;



