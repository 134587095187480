import styled, {keyframes} from "styled-components";
import React from "react";
import {Button} from "./button";
import {useUnlockPositionPrice} from "../api/requests/bag";
import {t} from "i18next";


interface Props {
    onUnlock: () => Promise<void>;
    onClose: () => void;
    loading?: boolean;
}

export const UnlockPosition = (props: Props) => {
    const {data} = useUnlockPositionPrice()

    return (
        <Overlay>
            <AnimatedContainer>
                <svg
                    onClick={props.onClose}
                    style={{
                        position: 'absolute',
                        top: 24,
                        right: 24,
                        cursor: 'pointer'
                    }}
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M14 2L2 14" stroke="#F7F7F7" stroke-width="2" stroke-miterlimit="10"
                          stroke-linecap="square"/>
                    <path d="M14 14L2 2" stroke="#F7F7F7" stroke-width="2" stroke-miterlimit="10"
                          stroke-linecap="square"/>
                </svg>
                <Lock/>
                <TextContainer>
                    <Title>{t("storage.unlockTitle1")} <br/>
                        {t("storage.unlockTitle2")}</Title>
                    <Description>{t("storage.unlockDesc")}</Description>
                    <PriceContainer>
                        <img
                            style={{
                                width: 40,
                                height: 40,
                            }}
                            src={'/images/icon-coin.png'} alt={'coin'}/>
                        <Price>
                            {data?.result?.price}
                        </Price>
                    </PriceContainer>
                </TextContainer>
                <Button
                    loading={props.loading}
                    onClick={props.onUnlock}
                >
                    {t("storage.open")}
                </Button>
            </AnimatedContainer>
        </Overlay>

    )
}

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;


const Container = styled.div`
    position: absolute;
    z-index: 99999;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px 20px 0 0;
    height: 58%;
    background: #021127;
    width: 100%;
    box-sizing: border-box;
    padding: 32px 24px;
`

const AnimatedContainer = styled(Container)`
    animation: ${fadeIn} 0.5s ease-in-out;
`;


const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.5);
`

const Title = styled.div`
    color: white;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
`


const Description = styled.div`
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    opacity: 0.4;
`

const Price = styled.div`
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
`

const PriceContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
`

const Lock = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="105" height="106" viewBox="0 0 105 106" fill="none">
            <g opacity="0.6">
                <path
                    d="M94.2401 104.047C66.3224 106.199 39.01 106.199 11.102 104.047C6.5099 103.625 2.36934 99.4842 1.95624 94.9017C-0.195701 66.9841 -0.195701 39.6717 1.95624 11.7636C2.37895 7.17154 6.51951 3.03096 11.102 2.61786C39.0196 0.46592 66.3321 0.46592 94.2401 2.61786C98.8322 3.04057 102.973 7.18115 103.386 11.7636C105.538 39.6813 105.538 66.9937 103.386 94.9017C102.963 99.4938 98.8226 103.634 94.2401 104.047Z"
                    fill="#104675"/>
                <path
                    d="M11.1022 104.048C9.44026 103.836 16.5782 100.359 16.5782 100.359L91.8482 100.272C91.8482 100.272 96.008 102.722 96.0561 103.683C96.1041 104.653 35.9361 107.237 11.1022 104.048Z"
                    fill="#103E61"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15.0604 5.70191C15.0604 5.70191 9.45956 3.48273 10.1609 2.743C44.7265 -2.03163 95.7391 1.58056 95.1723 2.743C94.6055 3.89582 91.3488 5.61544 90.1671 5.70191C89.6609 5.73865 76.0201 5.74416 60.5 5.73836L58.5 5.7375L56.5 5.73664C36.5201 5.72701 15.0604 5.70191 15.0604 5.70191Z"
                      fill="#1F6391"/>
                <path d="M56.5 5.73664C57.8423 5.73728 58.5 6.99988 60.5 5.73836L58.5 5.7375L56.5 5.73664Z"
                      fill="#1F6391"/>
                <path
                    d="M91.2142 100.358C79.0587 101.292 67.0261 101.787 55 101.844C47.0085 101.882 39.0199 101.726 31 101.376C29.9246 101.329 28.0766 101.898 27 101.844C25.8413 101.786 25.454 101.105 24.2937 101.039C20.9102 100.846 17.5194 100.619 14.1189 100.358C9.86305 99.9643 6.02028 96.1311 5.636 91.8753C3.64737 65.9943 3.64737 40.6705 5.636 14.7799C6.02988 10.524 9.86305 6.68129 14.1189 6.29701C30.3729 5.0481 46.4071 4.58354 62.5 4.90333L66 4.49988L69 5.07502C76.3757 5.31802 83.7715 5.72535 91.2142 6.29701C95.4701 6.6909 99.3129 10.524 99.6971 14.7799C101.686 40.6609 101.686 65.9847 99.6971 91.8753C99.3033 96.1311 95.4701 99.9739 91.2142 100.358Z"
                    fill="#105183"/>
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M42.5 49V43C42.5 37.4772 46.9772 33 52.5 33C58.0228 33 62.5 37.4772 62.5 43V49C65.8138 49 68.5 51.6862 68.5 55V67C68.5 70.3138 65.8138 73 62.5 73H42.5C39.1863 73 36.5 70.3138 36.5 67V55C36.5 51.6862 39.1863 49 42.5 49ZM58.5 49V43C58.5 39.6863 55.8138 37 52.5 37C49.1862 37 46.5 39.6863 46.5 43V49H58.5ZM52.5 57C53.6046 57 54.5 57.8954 54.5 59V63C54.5 64.1046 53.6046 65 52.5 65C51.3954 65 50.5 64.1046 50.5 63V59C50.5 57.8954 51.3954 57 52.5 57Z"
                  fill="white" fill-opacity="0.2"/>
        </svg>
    )
}


