export function getChestsById(id?: number) {
    if (id === 1) {
        return "initial"
    } else if (id === 2) {
        return "golden"
    } else if (id === 3) {
        return  "adamant"
    } else if (id === 4) {
        return  "emerald"
    } else if (id === 5) {
        return "ton"
    }
    return "unknown"
}