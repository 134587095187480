import {useSendClicksOnEveryPage} from "../../hooks/useSendClicksOnEveryPage";
import {Menu} from "../../components/menu";
import styled from "styled-components";
import {useParams} from 'react-router';
import {Header} from "../../components/header";
import {useMeStore} from "../../store/me-store";
import {Button} from "../../components/button";
import {useNavigate} from "react-router-dom";
import {MisteryBox} from "../../components/icons/mistery-box";
import {Star} from "../../components/icons/star";
import {Coins} from "../../components/icons/coins";
import {t} from "i18next";

const data = [
    {
        icon: <MisteryBox style={{width: '70%', height: 'auto'}}/>
    },
    {
        icon: <Star/>
    },
    {
        icon: <Coins/>
    },
]

export const Partner = () => {
    useSendClicksOnEveryPage()
    const {id} = useParams();
    const nav = useNavigate()
    const dataT = t('partners.data', { returnObjects: true }) as any


    return (
        <Container>
            <Header isWithoutPadding user={useMeStore(state => state.user)}/>
            <Column>
                <Title>
                    {t('partners.title')}
                </Title>
                {data[Number(id) - 1].icon}
                <Row>
                    {[0, 1, 2].map((item) => {
                        return <Step key={item} title={item + 1} isActivated={Number(id) === item + 1}/>
                    })}
                </Row>
                <Title>
                    {dataT[Number(id) - 1].title}
                </Title>
                <P>
                    <span dangerouslySetInnerHTML={{__html: dataT[Number(id) - 1].description}}/>
                </P>
            </Column>
            <ButtonRow>
                <Button onClick={() => {
                        if (Number(id) > 1) {
                            nav(`/partner/${Number(id) - 1}`)
                        } else {
                            nav(`/digger`)
                        }
                    }}
                    type={'secondary'}>
                    {t('back')}
                </Button>
                <Button onClick={() => {
                        if (Number(id) < 3) {
                            nav(`/partner/${Number(id) + 1}`)
                        } else {
                            nav(`/`)
                        }
                    }}
                >
                    {Number(id) < 4 ? t("next") : t("finish")}
                </Button>
            </ButtonRow>
            <Menu isAbsolute/>
        </Container>
    )
}


const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    gap: 10px;
`


const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
`

const P = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: center;
    color: rgba(204, 208, 212, 1);
    height: 16%;

`


const Title = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;  
    @media (max-height: 690px) {
        font-size: 24px;
    }
`

const Container = styled.div`
    display: flex;
    scrollbar-width: none;
    width: 100%;
    background: linear-gradient(180deg, #000 0%, #0A0F1E 100%);
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 16px;
    box-sizing: border-box;
    padding-bottom: 90px;
    height: 100vh;


`;


const Step = (props: any) => {

    if (!props.isActivated) {
        return (
            <StepInActive>
                {props.title}
            </StepInActive>
        )
    }


    return (
        <StepWrapper>
            <StepInner>
                <StepTitle>{props.title}</StepTitle>
            </StepInner>
        </StepWrapper>
    )
}

const Row = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 8px;
`

const StepInActive = styled.div`
    border-radius: 16px;
    background: #151721;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #35485C;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

`


const StepWrapper = styled.div`
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #384E63;
    box-sizing: border-box;
`


const StepInner = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    box-sizing: border-box;

    padding: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 2px #49617A;

    background: radial-gradient(50% 50% at 50% 50%, #1E2C3D 0%, #384E63 100%);

`


const StepTitle = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

