import styled from "styled-components";
import {ItemBox} from "./icons/item-box";
import React from "react";
import {useWidth} from "../hooks/useWidth";
import {Lock2Icon} from "./icons/lock2";

interface Props {
    image?: string;
    onClick?: (element: HTMLDivElement | null) => void;
    isSelected?: boolean;
    count?: number;
    isLocked?: boolean;
    isFirstLocked?: boolean;

}

export const BagItem = (props: Props) => {
    const WIDTH = useWidth()

    return (
        <Relative onClick={(e) => {
            if (props.onClick) {
                props.onClick(e.currentTarget)
            }
        }}>
            {props.isSelected &&
                <Border
                    style={{
                        width: WIDTH / 3,
                        height: WIDTH / 3,
                        position: 'absolute',

                    }}/>
            }
            <ItemBox
                style={{
                    width: WIDTH / 3,
                    height: WIDTH / 3,
                    borderColor: 'red',
                    borderWidth: '20px',
                }}
            />
            {props?.image &&
                <div style={{
                    zIndex: 1,
                    position: "absolute",
                    top: '7%',
                    left: '6%',
                }}>
                    <img
                        style={{
                            width: WIDTH / 3.5,
                            height: WIDTH / 3.5,
                        }}
                        src={props.image} alt={'chest'}/>

                </div>
            }


            {props.isLocked &&
                <Lock2Icon
                    style={{
                        width: WIDTH / 4.5,
                        height: WIDTH / 4.5,
                        position: 'absolute',
                        top: '13%',
                        left: '16%',
                    }}/>
            }
            {props.isFirstLocked &&
                <Add/>
            }
        </Relative>
    )
}


const Relative = styled.div`
    position: relative;
`


const Border = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="105" height="105" viewBox="0 0 105 105" fill="none">
            <path
                d="M101.891 93.7748C101.53 97.6413 97.967 101.2 94.1151 101.553C66.2815 103.698 39.053 103.698 11.2291 101.553C7.36257 101.191 3.80361 97.6283 3.45121 93.7763C1.30626 65.9428 1.30622 38.7144 3.45109 10.8906C3.81289 7.02403 7.37559 3.46506 11.2276 3.11265C39.0611 0.967698 66.2895 0.967662 94.1133 3.11252C97.9797 3.4743 101.539 7.03688 101.891 10.8888C104.036 38.7224 104.036 65.9509 101.891 93.7748Z"
                stroke="url(#paint0_linear_214_49407)" stroke-width="3"/>
            <defs>
                <linearGradient id="paint0_linear_214_49407" x1="52.6712" y1="0.00390625" x2="52.6712" y2="104.661"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#DDA35F"/>
                    <stop offset="0.185" stop-color="#F2EDB9"/>
                    <stop offset="1" stop-color="#693C1D"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

const Add = () => {
    return <AddContainer>
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
            <path
                d="M9.78571 3.57143H6.92857V0.714286C6.92857 0.32 6.60857 0 6.21429 0H4.78571C4.39143 0 4.07143 0.32 4.07143 0.714286V3.57143H1.21429C0.82 3.57143 0.5 3.89143 0.5 4.28571V5.71429C0.5 6.10857 0.82 6.42857 1.21429 6.42857H4.07143V9.28571C4.07143 9.68 4.39143 10 4.78571 10H6.21429C6.60857 10 6.92857 9.68 6.92857 9.28571V6.42857H9.78571C10.18 6.42857 10.5 6.10857 10.5 5.71429V4.28571C10.5 3.89143 10.18 3.57143 9.78571 3.57143Z"
                fill="#F7F7F7"/>
        </svg>
    </AddContainer>
}


const AddContainer = styled.div`
    display: inline-flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    border: 1px solid #ADEF61;
    background: linear-gradient(180deg, #83DA0F 0%, #1EB649 95.97%);
    box-shadow: 0px 2px 0px 0px #158832;
    position: absolute;
    bottom: 6px;
    right: 0;
    z-index: 100;
`