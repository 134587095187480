import React, {useState, useEffect} from "react";
import styled, {keyframes} from "styled-components";
import {ChestResult} from "../api/requests/model";
import {Lopata} from "./icons/lopata";
import {GameAnalytics} from "gameanalytics";
import {t} from "i18next";

interface Props {
    onClose: () => void;
    chest: ChestResult | null;
}

export const DigModal = (props: Props) => {
    const [openedIndex, setOpenedIndex] = useState<number | null>(null);

    useEffect(() => {
        if (openedIndex !== null) {
            const timer = setTimeout(() => {
                props.onClose();
            }, 700);
            return () => clearTimeout(timer);
        }
    }, [openedIndex, props]);

    return (
        <AnimatedContainer>

            <Row>
                {[0, 1, 2, 3].map((index) =>
                    <GuessItem
                        key={index}
                        chest={props.chest}
                        isOpen={openedIndex === index}
                        onClick={() => {
                            if (!openedIndex && openedIndex !== 0)
                                setOpenedIndex(index)
                        }}
                    />
                )}
            </Row>
            <Column>
            <Title>{t("digModal.title")}</Title>
            <Desc>
                {t("digModal.desc")}
            </Desc>
            </Column>
         </AnimatedContainer>
    );
}


const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;


const GuessItem = ({chest, isOpen, onClick}: { chest: ChestResult | null; isOpen: boolean; onClick: () => void; }) => {
    return (
        <div
            style={{
                cursor: 'pointer',
                position: 'relative',
                width: 140,
                height: 140,
            }}
            onClick={onClick}>
            {!isOpen ?
                <Lopata
                    style={{
                        width: 140,
                        height: 140,
                    }}
                />
                :
                <Relative>
                    <img src={'/images/ground.png'}
                         style={{
                             width: 178,
                             height: 198,
                         }}
                    />
                    {chest?.chest?.art_url &&
                        <AnimatedChest
                            src={chest?.chest?.art_url}
                            style={{
                                width: 110,
                                height: 110,
                                position: 'absolute',
                                top: 23,
                                left: 32,
                            }}
                            isOpen={isOpen}
                        />
                    }
                </Relative>
            }
        </div>
    );
}

const Relative = styled.div`
    position: relative;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 4px;
`;

const Row = styled.div`
    display: flex;
     flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 40px;
    box-sizing: border-box;
`;


const Desc = styled.div`
    color: #FFF;
    text-align: center;
    opacity: 0.6;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
`;

const Title = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 128.571% */
`;

const Container = styled.div`
    position: absolute;
    z-index: 99999;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    height: calc(100vh - 68px);
    background: linear-gradient(180deg, #000 0%, #1E130A 100%);
    padding: 20px 20px 20px 20px;
    width: 100%;
    box-sizing: border-box;

    @media (min-height: 800px) {
        padding: 20px 20px 98px 20px;
    }
`;

const openAnimation = keyframes`
    from {
        transform: scale(0.8);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
`;

const AnimatedChest = styled.img<{ isOpen: boolean }>`
    animation: ${({isOpen}) => isOpen ? openAnimation : 'none'} 0.5s ease-in-out;
`;


const AnimatedContainer = styled(Container)`
    animation: ${fadeIn} 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;
