import styled from "styled-components";
import {t} from "i18next";


interface Props {
    isStartInfoVisible: boolean;
    onClosed: () => void;
}

const StartInfo = ({isStartInfoVisible, onClosed}: Props) => {


    if (!isStartInfoVisible) {
        return null;
    }

    return (
        <Container>
            <svg
                onClick={() => {
                    onClosed()
                }}
                style={{position: 'absolute', top: 16, right: 16, cursor: 'pointer'}}
                xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M10.5 1.5L1.5 10.5" stroke="#F7F7F7" stroke-width="2" stroke-miterlimit="10"
                      stroke-linecap="square"/>
                <path d="M10.5 10.5L1.5 1.5" stroke="#F7F7F7" stroke-width="2" stroke-miterlimit="10"
                      stroke-linecap="square"/>
            </svg>
            <Title>{t('startInfo.title')}</Title>
            <Description dangerouslySetInnerHTML={{__html: t('startInfo.text')}}/>
        </Container>
    );
}


export {StartInfo};

const Container = styled.div`
     display: flex;
    width: 90%;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid #1F71AD;
    background: #0B1E2C;
    box-shadow: 0px 16px 48px 0px rgba(11, 30, 44, 0.40);
    box-sizing: border-box;
    position: fixed;
    bottom: 110px;
    z-index: 100000;
 
`


const Title = styled.div`

    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
`


const Description = styled.span`
    color: #FFF;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
`