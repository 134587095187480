import {useEffect, useState} from 'react'
import {useGallery} from "../api/requests/gallery";


function preloadImage(src: string) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = function () {
            resolve(img)
        }
        img.onerror = img.onabort = function () {
            console.log('ERROR')
            reject(src)
        }
        img.src = src
    })
}

const imageList = [
    '../images/deck.png',
    '../images/deck-bg.png',
    '../images/icon-coin.png',
    '../chests/gold_close.png',
    '../images/ground.png',
    '../images/head-title-red.png',
    '../images/ground.png',
    '../images/head-title-gray.png',
    '../images/deck-bg-dark.png'
]


export function useLocalImagePreloader() {
    const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false)

    useEffect(() => {
        let isCancelled = false

        async function effect() {
            if (isCancelled) {
                return
            }

            const imagesPromiseList: Promise<any>[] = []
            for (const i of imageList) {
                imagesPromiseList.push(preloadImage(i))
            }

            await Promise.all(imagesPromiseList)

            if (isCancelled) {
                return
            }

            setImagesPreloaded(true)
        }

        effect()

        return () => {
            isCancelled = true
        }
    }, [imageList])

    return {imagesPreloaded}
}


function gwtUniqueImageList(imageList: string[]) {
    return Array.from(new Set(imageList))
}


interface Props {
    imageList: string[]
    skip?: boolean
}

export function useFromApiImagePreloader(props: Props) {
    const {imageList: imageList2, skip} = props
    const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false)

    useEffect(() => {

        let isCancelled = false
        if (imageList.length === 0) {
            isCancelled = true
            setImagesPreloaded(false)
            return
        }


        async function effect() {

            if (imageList2.length === 0 || skip) {
                return
            }

            const imagesPromiseList: Promise<any>[] = []
            for (const i of gwtUniqueImageList(imageList2)) {

                imagesPromiseList.push(preloadImage(i))
            }

            await Promise.all(imagesPromiseList)

            if (isCancelled) {
                return
            }

            setImagesPreloaded(true)
        }

        effect().then()


    }, [imageList2, skip])

    return {imagesPreloaded}
}