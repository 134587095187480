import {HeaderIcon} from "./icons/header";
import React from "react";
import styled from "styled-components";
import {SettingsButton} from "./settings-button";
import {useWidth} from "../hooks/useWidth";
import {Profile} from "./icons/profile";
import {User} from "../api/requests/model";
import {Link} from "react-router-dom";
import {Ton} from "./icons/ton";


interface Props {
    user: User | null
    isWithoutPadding?: boolean
}

export const Header = (props: Props) => {
    return (
        <Container isWithoutPadding={props.isWithoutPadding}>
            <Link
                to={'/profile'}
                style={{
                    textDecoration: 'none'
                }}>
                <ProfileContainer>
                    <Profile/>
                    <Name>
                        {props?.user?.username || props?.user?.first_name || props?.user?.tg_id || 'User'}
                    </Name>
                </ProfileContainer>
            </Link>
            <Link
                style={{
                    textDecoration: 'none'
                }}
                to={'/profile'}>
                <Row>
                    <Ton/>
                    {props?.user?.ton_amount || 0}
                </Row>
            </Link>
        </Container>
    )
}


const Container = styled.div<{ isWithoutPadding?: boolean }>`
    background-color: #000508;
    display: flex;
    align-items: center;
    padding: ${props => props.isWithoutPadding ? '0' : '15px 16px 16px 16px'};

    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
`

const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`


const Name = styled.div`
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 7px;
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-decoration: none;

`


