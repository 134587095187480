import React from "react";
import styled from "styled-components";
import {t} from "i18next";

interface LevelProps {
    exp: number,
    expTo: number
    level: number
}

function formatNumber(num: number) {
    if (num > 999) {
        return (num / 1000).toFixed(1) + 'к'
    }
    return num
}

export const Level = (props: LevelProps) => {
    return (
        <Relative>
            <Col>
                <Row>
                    <TextW style={{top: 46, left: 11}}>
                        {t('level')} {props.level || 1} <TextG>/ 20</TextG>
                    </TextW>
                    <TextW style={{top: 47, right: 18}}>
                        {formatNumber(props.exp) || 1} / <TextG>{formatNumber(props.expTo) || 1}</TextG>
                    </TextW>
                </Row>
                <ProgressContainer>
                    <ProgressBar width={`${(props.exp / props.expTo) * 100}%`}/>
                </ProgressContainer>
            </Col>
        </Relative>
    )
}


const TextW = styled.div`
    color: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;  
`
const TextG = styled.span`
    color: #777;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
`

const Relative = styled.div`
    position: relative;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;

`
const ProgressBar = styled.div<{ width: string }>`
    width: ${(props) => props.width};
    height: 100%;
    z-index: 1;
    border-radius: 10px;
    background: linear-gradient(90deg, #B283FE 0%, #4E7FC8 20%, #8EE89F 40%, #83CAFE 60%, #4E5AC8 80%);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
`


const ProgressContainer = styled.div`
    width: 100%;
    height: 10px;
    flex-shrink: 0;
    border-radius: 100px;
    border: 1px solid #A7C3F0;
    background: #172842;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.36) inset, 0px -3px 1.8px 0px rgba(29, 10, 58, 0.25) inset, 0px -5px 4.5px 0px rgba(29, 10, 58, 0.25) inset;
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
 
`