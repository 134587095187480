import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import ru from "./locale/ru.json";
import en from "./locale/en.json";

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: "en",
        resources: {
            ru: {
                translation: ru
            },
            en: {
                translation: en
            }
        },
        supportedLngs: ['en', 'ru'],

        interpolation: {
            escapeValue: false
        }
    });
