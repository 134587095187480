import React from "react";

export const WoodSpinnerIcon = (props:React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="52" height="56" viewBox="0 0 52 56" fill="none">
            <g filter="url(#filter0_dd_578_256567)">
                <path
                    d="M4.09455 37.8182C2.74836 34.8225 2 31.4975 2 28C2 14.7455 12.7455 4 26 4C39.2545 4 50 14.7455 50 28C50 41.2545 39.2545 52 26 52C20.216 52 14.912 49.9535 10.7665 46.5476"
                    stroke="#DAA97F" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"
                    shape-rendering="crispEdges"/>
            </g>
            <defs>
                <filter id="filter0_dd_578_256567" x="0" y="0" width="52" height="56" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.933087 0 0 0 0 0.791774 0 0 0 0 0.62311 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_578_256567"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="-2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.880222 0 0 0 0 0.714208 0 0 0 0 0.560341 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="effect1_dropShadow_578_256567" result="effect2_dropShadow_578_256567"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_578_256567" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}