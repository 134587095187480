import {useEffect, useRef} from "react";
import {useIsConnectionRestored, useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import {checkProof, generatePayload} from "../api/requests/blockchain";
import {GameAnalytics} from "gameanalytics";
import {Event} from "../constant";

const payloadTTLMS = 1000 * 60 * 20;

export function useBackendAuth({mutate}: {mutate: () => Promise<any>}) {
    const isConnectionRestored = useIsConnectionRestored();
    const wallet = useTonWallet();
    const [tonConnectUI] = useTonConnectUI();
    const interval = useRef<ReturnType<typeof setInterval> | undefined>();

    useEffect(() => {

        if (!isConnectionRestored) {
            return;
        }

        clearInterval(interval.current);

        if (!wallet) {
            const refreshPayload = async () => {
                tonConnectUI.setConnectRequestParameters({state: 'loading'});

                const value1 = await generatePayload();
                if (!value1.result.payload) {
                    tonConnectUI.setConnectRequestParameters(null);
                } else {
                    tonConnectUI.setConnectRequestParameters({
                        state: 'ready',
                        value: {
                            tonProof: value1.result.payload as string
                        }
                    });
                }
            }

            refreshPayload().then()
            setInterval(refreshPayload, payloadTTLMS);
            return;
        }

        if (wallet.connectItems?.tonProof && !('error' in wallet.connectItems.tonProof)) {
            checkProof(wallet.connectItems.tonProof.proof, wallet.account).then(result => {
                if (result) {
                    console.log('Proof is valid', wallet);
                     mutate().then((e) => {
                         tonConnectUI.disconnect();
                     });
                     //@ts-ignore
                    graspil.push({event: 'Подключился кошелек'});
                    GameAnalytics.addDesignEvent(Event.TON_CONNECT, 1)

                } else {
                    alert('Please try another wallet');
                    tonConnectUI.disconnect();
                }
            })
        } else {
            tonConnectUI.disconnect();
        }

    }, [wallet, isConnectionRestored])
}