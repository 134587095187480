import useSWR from "swr";
import {fetchSWR} from "../apiClient";
import {Bag, Item, Response} from "./model";

export const useBagList = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/api/user-item/list',
        (url: any) => fetchSWR<Response<Bag[]>>({input: url})
    );
}


export const useUnlockPositionPrice = () => {
    return useSWR('/api/user/item-slot/price',
        (url: any) => fetchSWR<Response<{
            price: number
        }>>({input: url})
    );
}


export const useUpgradeInfo = (itemId?: number, to?: string) => {
    return useSWR((itemId && to) ? `/api/user-item/upgrade/info?item_id=${itemId}&to=${to}` : undefined,
        (url: any) => fetchSWR<Response<Item>>({input: url})
    );
}