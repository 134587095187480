import styled from "styled-components";
import {Bag} from "../api/requests/model";
import {BagItem} from "./bag-item";
import React, {useMemo} from "react";
import {getItems} from "../pages/bag";


interface Props {
    data: Bag[]
    onSelect: (id: number, element: HTMLDivElement | null) => void;
    selectedID?: number;
    onUnlock: () => void;
    itemSlotCnt: number;
}

export const BagList = (props: Props) => {
    const maxItems = 50;
    const recurringCount = props.data.reduce((totalCount, bagData) => {
        const itemCount = (bagData.item?.cnt || 0) + (bagData.item_artefact?.cnt || 0) + (bagData.item_super_artefact?.cnt || 0);
        return totalCount + itemCount;
    }, 0);

    return (
        <GridList>
            {(props?.data && props?.data.map((item, i) => {
                const bagData: any = props?.data[i];
                const itemsToRender = [];


                if (bagData && bagData.item) {
                    itemsToRender.push(bagData.item);
                }

                if (bagData && bagData.item_artefact) {
                    itemsToRender.push(bagData.item_artefact);
                }

                if (bagData && bagData.item_super_artefact) {
                    itemsToRender.push(bagData.item_super_artefact);
                }

                return itemsToRender?.map((item, index) => {
                    const itemCount = item?.cnt || 1;
                    return (
                        <>
                            {Array.from({length: itemCount}).map((_, boxIndex) => {
                                return (
                                    <div style={{position: 'relative'}} key={`${i}-${boxIndex}`}>
                                        <BagItem
                                            isSelected={props.selectedID === item.id}
                                            onClick={(element) => props.onSelect(item?.id, element)}
                                            image={item?.art_url}
                                        />
                                    </div>
                                )
                            })}
                        </>
                    );
                })
            }))}

            {Array.from({length: props.itemSlotCnt - recurringCount}).map((_, i) => {
                return (
                    <BagItem key={i}/>
                );
            })}

            {Array.from({length: (maxItems - (props.itemSlotCnt - recurringCount) + props?.data?.length)}).map((_, i) => {
                return (
                    <BagItem
                        isFirstLocked={i === 0}
                        onClick={props.onUnlock}
                        isLocked
                        key={i}
                    />
                );
            })}
        </GridList>
    );
};


const GridList = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    justify-items: center; /* Center items horizontally within their columns */
    overflow: auto;
    padding-bottom: 70px;

`
