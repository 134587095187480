import {useEffect, useRef, useState} from "react";
import {IS_VIBRATION} from "../constant";
import {useHapticFeedback} from "@telegram-apps/sdk-react";
import {random, TapAnimation} from "../components/effects/animated-tap";
import {EffectsCanvasRef} from "../components/fire-ball";


interface Props {
    onTouchStart?: (e: PointerEvent) => void;
    tapToCoin: number;
    isDisabled?: boolean;
    isVibration?: boolean;
    isX2?: boolean;
}


function sleep(timeout: number) {
    return new Promise((resolve) => setTimeout(resolve, timeout))
}


export const useFinger = (props: Props) => {
    const isMoveRef = useRef(false);
    const effectsRef = useRef<EffectsCanvasRef>();

    const tapAreaRef = useRef<HTMLDivElement>(null);
    const lastOffsetX = useRef(0);
    const pointerDownRef = useRef<PointerEvent[]>([]);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const tapToCoinRef = useRef(props.tapToCoin);
    const onTouchStartRef = useRef(props.onTouchStart); // Add this ref to store the latest onTouchStart callback
    const isVibrationRef = useRef(props.isVibration);
    const isX2Ref = useRef(props.isX2); // Added isX2Ref


    const isDisabledRef = useRef(props.isDisabled);

    useEffect(() => {
        isDisabledRef.current = props.isDisabled;
    }, [props.isDisabled]);

    useEffect(() => {
        isX2Ref.current = props.isX2;
    }, [props.isX2]);

    const haptic = useHapticFeedback();


    useEffect(() => {
        isVibrationRef.current = props.isVibration;
    }, [props.isVibration]);


    useEffect(() => {
        onTouchStartRef.current = props.onTouchStart;
    }, [props.onTouchStart]);

    useEffect(() => {
        tapToCoinRef.current = props.tapToCoin;
    }, [props.tapToCoin]);


    useEffect(() => {
        const onPointerDown = (e: PointerEvent) => {

            if (tapAreaRef.current && tapAreaRef.current.contains(e.target as Node)) {
                pointerDownRef.current.push(e);
                isMoveRef.current = e.isPrimary;
                lastOffsetX.current = e.clientX;

                if (!intervalRef.current) {
                    intervalRef.current = setTimeout(async () => {

                        while (1 + 1 === 2) {
                            if (!intervalRef.current || !pointerDownRef.current.length) return;

                            for (let pe of pointerDownRef.current) {
                                if (!intervalRef.current) return;
                                handleClick(pe);
                                await sleep(random(50, 100));
                            }
                        }
                    }, 500);
                }
            }
        };

        const onPointerMove = (e: PointerEvent) => {
            pointerDownRef.current = pointerDownRef.current.map(ev => ev.pointerId === e.pointerId ? e : ev);

            if (isMoveRef.current) {
                lastOffsetX.current = e.clientX;
            }
        };

        const onPointerUp = (e: PointerEvent) => {
            pointerDownRef.current = pointerDownRef.current.filter(ev => ev.pointerId !== e.pointerId);

            if (pointerDownRef?.current && pointerDownRef?.current?.length === 0) {
                if (intervalRef.current) {
                    clearTimeout(intervalRef.current);
                }
                intervalRef.current = null;
            }

            if (tapAreaRef.current && tapAreaRef.current.contains(e.target as Node)) {
                isMoveRef.current = false;
                handleClick(e);
            }
        };

        const onPointerCancel = (e: PointerEvent) => {
            pointerDownRef.current = pointerDownRef.current.filter(ev => ev.pointerId !== e.pointerId);
            if (pointerDownRef.current.length === 0) {
                if (intervalRef.current) {
                    clearTimeout(intervalRef.current);
                }
                intervalRef.current = null;
            }
        }

        window.addEventListener("pointerdown", onPointerDown);
        window.addEventListener("pointermove", onPointerMove);
        window.addEventListener("pointerup", onPointerUp);
        window.addEventListener("pointercancel", onPointerCancel);
        return () => {
            window.removeEventListener("pointerdown", onPointerDown);
            window.removeEventListener("pointermove", onPointerMove);
            window.removeEventListener("pointerup", onPointerUp);
            window.removeEventListener("pointercancel", onPointerCancel);

            pointerDownRef.current = [];
            if (intervalRef.current) {
                clearTimeout(intervalRef.current);
            }
            intervalRef.current = null;
        };
    }, []);


    const handleClick = (e: any) => {
        e?.preventDefault();
        if (isDisabledRef.current) return;

        if (isVibrationRef.current) {
            haptic.impactOccurred('medium');
        }
        if (onTouchStartRef.current) onTouchStartRef.current(e);
        TapAnimation.animate(e, tapToCoinRef.current, false, 500).then(() => {
            if (isX2Ref.current) {
                effectsRef.current?.showFireballEffect(e, 0.2);
            }
        });
    };
    return {tapAreaRef, effectsRef};

}