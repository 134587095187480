import {InfoItem} from "./info-item";
import React from "react";
import styled from "styled-components";

import {t} from "i18next";

interface Props {
    tapProfit: number;
    hourProfit: number;

}

export const Info = (props: Props) => {
    return (
        <UpRow>
            <InfoItem title={t('profit_per_tap')} value={'+' + props.tapProfit?.toString()} color={'#78CA2B'}/>
            <InfoItem title={t('profit_per_hour')} value={props.hourProfit?.toString()} color={'#78CA2B'}/>
        </UpRow>
    )
}

const UpRow = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    gap: 8px;
`