import {t} from "i18next";

const getIcon = (type: string) => {
    switch (type) {
        case 'chest_reward':
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M12 12C12.4142 12 12.75 11.6642 12.75 11.25C12.75 10.8358 12.4142 10.5 12 10.5C11.5858 10.5 11.25 10.8358 11.25 11.25C11.25 11.6642 11.5858 12 12 12Z"
                    fill="url(#paint0_linear_596_243091)"/>
                <path
                    d="M21 12V18.75C20.9994 18.9487 20.9202 19.1392 20.7797 19.2797C20.6392 19.4202 20.4487 19.4994 20.25 19.5H3.75C3.55127 19.4994 3.36085 19.4202 3.22033 19.2797C3.0798 19.1392 3.00059 18.9487 3 18.75V12H9.885C9.99629 12.3149 10.1766 12.601 10.4128 12.8372C10.649 13.0734 10.9351 13.2537 11.25 13.365V14.25C11.25 14.4489 11.329 14.6397 11.4697 14.7803C11.6103 14.921 11.8011 15 12 15C12.1989 15 12.3897 14.921 12.5303 14.7803C12.671 14.6397 12.75 14.4489 12.75 14.25V13.365C13.0649 13.2537 13.351 13.0734 13.5872 12.8372C13.8234 12.601 14.0037 12.3149 14.115 12H21Z"
                    fill="url(#paint1_linear_596_243091)"/>
                <path
                    d="M21 8.25V10.5H14.115C13.9614 10.0616 13.6754 9.6818 13.2965 9.41308C12.9176 9.14436 12.4645 9.00002 12 9.00002C11.5355 9.00002 11.0824 9.14436 10.7035 9.41308C10.3246 9.6818 10.0386 10.0616 9.885 10.5H3V8.25C3.00036 7.25555 3.39556 6.30193 4.09875 5.59875C4.80193 4.89556 5.75555 4.50036 6.75 4.5H17.25C18.2445 4.50036 19.1981 4.89556 19.9013 5.59875C20.6044 6.30193 20.9996 7.25555 21 8.25Z"
                    fill="url(#paint2_linear_596_243091)"/>
                <defs>
                    <linearGradient id="paint0_linear_596_243091" x1="12.0044" y1="10.5" x2="12.0044" y2="11.9395"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#83DA0F"/>
                        <stop offset="1" stop-color="#1EB649"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_596_243091" x1="12.0533" y1="12" x2="12.0533" y2="19.1976"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#83DA0F"/>
                        <stop offset="1" stop-color="#1EB649"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_596_243091" x1="12.0533" y1="4.5" x2="12.0533" y2="10.2581"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#83DA0F"/>
                        <stop offset="1" stop-color="#1EB649"/>
                    </linearGradient>
                </defs>
            </svg>
        case 'chest_open':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M12 12C12.4142 12 12.75 11.6642 12.75 11.25C12.75 10.8358 12.4142 10.5 12 10.5C11.5858 10.5 11.25 10.8358 11.25 11.25C11.25 11.6642 11.5858 12 12 12Z"
                        fill="url(#paint0_linear_596_243102)"/>
                    <path
                        d="M21 12V18.75C20.9994 18.9487 20.9202 19.1392 20.7797 19.2797C20.6392 19.4202 20.4487 19.4994 20.25 19.5H3.75C3.55127 19.4994 3.36085 19.4202 3.22033 19.2797C3.0798 19.1392 3.00059 18.9487 3 18.75V12H9.885C9.99629 12.3149 10.1766 12.601 10.4128 12.8372C10.649 13.0734 10.9351 13.2537 11.25 13.365V14.25C11.25 14.4489 11.329 14.6397 11.4697 14.7803C11.6103 14.921 11.8011 15 12 15C12.1989 15 12.3897 14.921 12.5303 14.7803C12.671 14.6397 12.75 14.4489 12.75 14.25V13.365C13.0649 13.2537 13.351 13.0734 13.5872 12.8372C13.8234 12.601 14.0037 12.3149 14.115 12H21Z"
                        fill="url(#paint1_linear_596_243102)"/>
                    <path
                        d="M21 8.25V10.5H14.115C13.9614 10.0616 13.6754 9.6818 13.2965 9.41308C12.9176 9.14436 12.4645 9.00002 12 9.00002C11.5355 9.00002 11.0824 9.14436 10.7035 9.41308C10.3246 9.6818 10.0386 10.0616 9.885 10.5H3V8.25C3.00036 7.25555 3.39556 6.30193 4.09875 5.59875C4.80193 4.89556 5.75555 4.50036 6.75 4.5H17.25C18.2445 4.50036 19.1981 4.89556 19.9013 5.59875C20.6044 6.30193 20.9996 7.25555 21 8.25Z"
                        fill="url(#paint2_linear_596_243102)"/>
                    <defs>
                        <linearGradient id="paint0_linear_596_243102" x1="12.0044" y1="10.5" x2="12.0044" y2="11.8571"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFDA00"/>
                            <stop offset="1" stop-color="#F7B812"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_596_243102" x1="12.0533" y1="12" x2="12.0533" y2="18.7857"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFDA00"/>
                            <stop offset="1" stop-color="#F7B812"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_596_243102" x1="12.0533" y1="4.5" x2="12.0533" y2="9.92857"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFDA00"/>
                            <stop offset="1" stop-color="#F7B812"/>
                        </linearGradient>
                    </defs>
                </svg>
            )
        case 'withdraw':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M6 3H18C20.2 3 22 4.8 22 7C22 8.7 21 10.1 19.5 10.7V7C19.5 6.6 19.3 6.2 19.1 5.9C18.9 5.6 18.4 5.5 18 5.5H6C5.6 5.5 5.2 5.7 4.9 5.9C4.6 6.1 4.5 6.6 4.5 7V10.7C3 10.1 2 8.7 2 7C2 4.8 3.8 3 6 3Z"
                        fill="url(#paint0_linear_596_243168)"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M18 7V19C18 20.1 17.1 21 16 21H8C6.9 21 6 20.1 6 19V7H18ZM10.5 14.5L11.2 15.2V11C11.2 10.6 11.5 10.2 12 10.2C12.5 10.2 12.8 10.5 12.8 11V15.2L13.5 14.5C13.8 14.2 14.3 14.2 14.6 14.5C14.9 14.8 14.9 15.3 14.6 15.6L13.3 16.9C12.6 17.6 11.5 17.6 10.8 16.9L9.5 15.6C9.2 15.3 9.2 14.8 9.5 14.5C9.8 14.2 10.2 14.2 10.5 14.5Z"
                          fill="url(#paint1_linear_596_243168)"/>
                    <defs>
                        <linearGradient id="paint0_linear_596_243168" x1="12.0592" y1="3" x2="12.0592" y2="9.96667"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFDA00"/>
                            <stop offset="1" stop-color="#F7B812"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_596_243168" x1="12.0355" y1="7" x2="12.0355" y2="19.6667"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFDA00"/>
                            <stop offset="1" stop-color="#F7B812"/>
                        </linearGradient>
                    </defs>
                </svg>
            )
        case 'admin_profit':
            return (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M18 3H6C3.8 3 2 4.8 2 7C2 8.7 3 10.1 4.5 10.7V7C4.5 6.6 4.7 6.2 4.9 5.9C5.1 5.6 5.6 5.5 6 5.5H18C18.4 5.5 18.8 5.7 19.1 5.9C19.4 6.1 19.5 6.6 19.5 7V10.7C21 10.1 22 8.7 22 7C22 4.8 20.2 3 18 3ZM18 19V7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19ZM11.2 12.4L10.5 13.1C10.2 13.4 9.7 13.4 9.4 13.1C9.1 12.8 9.1 12.3 9.4 12L10.7 10.7C11.4 10 12.5 10 13.2 10.7L14.5 12C14.8 12.3 14.8 12.8 14.5 13.1C14.2 13.4 13.7 13.4 13.4 13.1L12.7 12.4V16.6C12.7 17 12.4 17.4 11.9 17.4C11.4 17.4 11.1 17.1 11.1 16.6V12.4H11.2Z"
                      fill="url(#paint0_linear_596_243193)"/>
                <defs>
                    <linearGradient id="paint0_linear_596_243193" x1="12.0592" y1="3" x2="12.0592" y2="20.2742"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#83DA0F"/>
                        <stop offset="1" stop-color="#1EB649"/>
                    </linearGradient>
                </defs>
            </svg>)
        default:
            return <div/>
    }
}


const getTitle = (type: string) => {
    return t(`transactionTitle.${type}`)
}


const getStatus = (status: string) => {
    return t(`transactionStatus.${status}`)
}

const getStatusColor = (status: string) => {
    switch (status) {
        case 'done':
            return '#1EB649'
        case 'pending':
            return '#FFDA00'
        case 'progress':
            return '#FFDA00'
        case 'error':
            return '#FF0000'
        default:
            return ''
    }
}


export {getIcon, getTitle, getStatus, getStatusColor};
