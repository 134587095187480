import styled from "styled-components";
import {useTonList} from "../api/requests/blockchain";
import {Transaction} from "../api/requests/model";
import {getIcon, getStatus, getStatusColor, getTitle} from "../utils/get-transaction";
import {Ton} from "../components/icons/ton";
import moment from "moment";
import {useUserInfo} from "../api/requests/user-info";
import React, {useEffect} from "react";
import {Menu} from "../components/menu";
import {Loader} from "../components/loader";
import {Withdraw} from "../withdraw";
import {t} from "i18next";

const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const Transactions = () => {
    const {data: user, mutate} = useUserInfo()

    const {data, mutate: mutateTrans, isLoading} = useTonList()
    const [isWithdraw, setIsWithdraw] = React.useState(false)
    useEffect(() => {
        //@ts-ignore
        graspil.push({event: 'Зашел в транзакции'})
    }, []);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <Container>
            <Row>
                <Balance>
                    <img
                        src="/images/icon-coin.png"
                        style={{
                            width: 30,
                            height: 30,
                        }}
                    />
                    {numberWithCommas(user?.result?.coin_cnt || 0)}
                </Balance>
                <Balance>
                    <Ton/>{user?.result?.ton_amount || 0}
                </Balance>
            </Row>
            <Warning>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g opacity="0.4" clip-path="url(#clip0_596_242906)">
                        <path
                            d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM9 11.5C9 11.776 8.776 12 8.5 12H7.5C7.224 12 7 11.776 7 11.5V7.5C7 7.224 7.224 7 7.5 7H8.5C8.776 7 9 7.224 9 7.5V11.5ZM8 6C7.448 6 7 5.552 7 5C7 4.448 7.448 4 8 4C8.552 4 9 4.448 9 5C9 5.552 8.552 6 8 6Z"
                            fill="#F7F7F7"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_596_242906">
                            <rect width="16" height="16" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <span>
                    {t('minWarning')} <TSpan>TON</TSpan>: 5
                </span>
            </Warning>
            {user?.result?.ton_address &&
                <Button onClick={() => setIsWithdraw(true)}>
                    {t('withdraw')} TON
                </Button>
            }
            <List>
                <ListTitle>
                    {t('transactions')}
                </ListTitle>
                {(!isLoading && !data?.result?.data) ?
                    <Empty/>
                    :
                    <>{data?.result?.data?.map((transaction) => <TransactionItem transaction={transaction}/>)}</>}
            </List>
            {isWithdraw &&
                <Withdraw balance={user?.result?.ton_amount || 0} onClose={async () => {
                    await mutate()
                    await mutateTrans()
                    setIsWithdraw(false)
                }}/>
            }
            <Menu isAbsolute/>

        </Container>
    )
}


const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 24px 16px;
    box-sizing: border-box;
    background: linear-gradient(180deg, #000000 0%, #133353 95.97%);

    &::-webkit-scrollbar {
        display: none;
    }

    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
    overflow: scroll;



`

const List = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    height: 70%;
`
const ListTitle = styled.div`
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 8px;
`
const Warning = styled.div`
    display: flex;
    height: 40px;
    padding: 8px 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #1C3246;
    background: #112232;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`

const TSpan = styled.span`
    color: #4DA3E1;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`

const Balance = styled.div`
    color: #FFF;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
`


const TransactionItem = ({transaction}: { transaction: Transaction }) => {
    return (
        <TContainer>
            <IconWrapper>
                {getIcon(transaction.type)}
            </IconWrapper>
            <TRow>
                <TColumn align={'flex-start'}>
                    <TTitle>{getTitle(transaction.type)}</TTitle>
                    <TDate>{moment(transaction.created_at).format('DD.MM.YYYY')}</TDate>
                </TColumn>
                <TColumn align={'flex-end'}>
                    <TAmount>TON: {transaction.amount > 0 ? "+" : ""}{transaction.amount}
                        <Ton style={{width: 20, height: 20}}/>
                    </TAmount>
                    <TStatus color={getStatusColor(transaction.status)}>{getStatus(transaction.status)}</TStatus>
                </TColumn>
            </TRow>
        </TContainer>
    )

}


const TContainer = styled.div`
    display: flex;
    padding: 8px 10px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #1C3246;
    background: #112232;
    width: 100%;
    box-sizing: border-box;
`

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #1C3146;
    margin-right: 10px;
`
const TRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const TColumn = styled.div<{ align: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${props => props.align};
    height: 100%;
    gap: 4px;
`
const TTitle = styled.div`
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
`

const TDate = styled.div`
    color: rgba(255, 255, 255, 0.60);;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`

const TAmount = styled.div`
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 4px;
`

const TStatus = styled.div<{ color: string }>`
    color: ${props => props.color};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`


const Button = styled.div`
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #84CCFF;
    background: linear-gradient(180deg, #68B5EC 0%, #2588CF 95.97%);
    box-shadow: 0px 3px 0px 0px #1D689F;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
`


const Empty = () => <EmptyContainer>
    <svg xmlns="http://www.w3.org/2000/svg" width="129" height="128" viewBox="0 0 129 128" fill="none">
        <path
            d="M23.9333 128V115.2C34.576 115.2 45.2698 115.2 55.9125 115.2C77.7414 115.239 95.6259 97.647 96.4667 75.4666L96.5166 75.3167V66.0792H92.6958V81.2417C87.2067 91.4858 76.4681 98.132 64.5167 98.1333C58.7544 98.1196 53.1027 96.5508 48.1583 93.5917L47.45 93.8667H23.9333V81.0666L0.5 104.533L23.9333 128Z"
            fill="url(#paint0_linear_621_92204)"/>
        <path
            d="M63.4333 100.283C78.3965 100.283 91.148 90.9363 96.2499 77.775C96.3209 77.0064 96.4204 76.2472 96.45 75.4667L96.5 75.3167V66.0792H92.6791V81.2416C87.19 91.4858 76.4514 98.132 64.4999 98.1333C58.7377 98.1196 53.086 96.5507 48.1416 93.5916L47.4333 93.8667H43.2041C48.9317 97.8981 55.8968 100.283 63.4333 100.283Z"
            fill="#809804"/>
        <path
            d="M105.067 0V12.8C94.424 12.8 83.7303 12.7999 73.0876 12.8C51.2586 12.7608 33.3741 30.3529 32.5334 52.5333L32.4834 52.6833V61.9208H36.3042V46.7583C41.7933 36.5142 52.5319 29.868 64.4834 29.8667C70.2456 29.8801 75.8973 31.4492 80.8417 34.4083L81.5501 34.1333H105.067V46.9333L128.5 23.4667L105.067 0Z"
            fill="url(#paint1_linear_621_92204)"/>
        <path opacity="0.1"
              d="M65.659 27.7669C50.6722 27.7669 37.9007 37.1287 32.7907 50.3107C32.7193 51.0805 32.62 51.8409 32.5904 52.6227L32.5403 52.7729V62.0249H36.3671V46.8386C41.8649 36.5783 52.6204 29.9216 64.5907 29.9203C70.362 29.9338 76.0226 31.5053 80.9748 34.4691L81.6842 34.1937H85.92C80.1834 30.1559 73.2073 27.7669 65.659 27.7669Z"
              fill="black"/>
        <path
            d="M98.6335 64C98.6335 82.8613 83.3434 98.1514 64.4821 98.1514C45.6209 98.1514 30.3308 82.8613 30.3308 64C30.3308 45.1388 45.6209 29.8487 64.4821 29.8487C83.3434 29.8487 98.6335 45.1388 98.6335 64Z"
            fill="url(#paint2_linear_621_92204)"/>
        <path opacity="0.1"
              d="M91.8224 63.9999C91.8224 79.0995 79.5817 91.3402 64.4821 91.3402C49.3825 91.3402 37.1418 79.0995 37.1418 63.9999C37.1418 48.9003 49.3825 36.6596 64.4821 36.6596C79.5817 36.6596 91.8224 48.9003 91.8224 63.9999Z"
              fill="black"/>
        <path
            d="M64.5866 42.7446C63.4127 42.7156 62.4629 43.6994 62.4583 44.873V47.0013H58.21C54.6194 47.0183 51.8082 49.9053 51.8082 53.4198V59.8257C51.7501 63.3651 54.6946 66.2441 58.21 66.2441H62.4583V76.9276H58.21C56.9972 76.8775 56.115 76.045 56.0649 74.7992C56.0695 73.6191 55.117 72.6546 53.9366 72.65C52.7561 72.6546 51.8036 73.6191 51.8082 74.7992C51.7832 78.3637 54.6946 81.2009 58.21 81.2009H62.4583V83.3293C62.4537 84.5094 63.4062 85.4697 64.5866 85.4743C65.7736 85.479 66.7363 84.5159 66.7317 83.3293V81.2009H71.0385C74.6039 81.209 77.4444 78.3136 77.4444 74.7992V68.3724C77.5025 64.8831 74.5956 61.9791 71.0385 61.9707H66.7317V51.2747H71.0385C72.2595 51.3329 73.1626 52.199 73.171 53.4198C73.179 54.6015 74.1339 55.5569 75.316 55.5523C76.5417 55.5814 77.4585 54.5701 77.4444 53.4198C77.4759 49.8725 74.5539 47.0013 71.0385 47.0013H66.7317V44.873C66.7271 43.6929 65.7671 42.74 64.5866 42.7446ZM58.21 51.2747H62.4583V61.9707H58.21C56.9722 61.9287 56.115 61.0548 56.0649 59.8257V53.4198C56.115 52.2157 57.0139 51.3081 58.21 51.2747ZM66.7317 66.2441H71.0385C72.2095 66.2441 73.171 67.1517 73.171 68.3724V74.7992C73.171 76.02 72.2512 76.8858 71.0385 76.9276H66.7317V66.2441Z"
            fill="#E4EFF8"/>
        <defs>
            <linearGradient id="paint0_linear_621_92204" x1="48.7924" y1="66.0792" x2="48.7924" y2="125.503"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#83DA0F"/>
                <stop offset="1" stop-color="#1EB649"/>
            </linearGradient>
            <linearGradient id="paint1_linear_621_92204" x1="80.7758" y1="0" x2="80.7758" y2="59.424"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#83DA0F"/>
                <stop offset="1" stop-color="#1EB649"/>
            </linearGradient>
            <linearGradient id="paint2_linear_621_92204" x1="64.6842" y1="29.8487" x2="64.6842" y2="91.6463"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFDA00"/>
                <stop offset="1" stop-color="#F7B812"/>
            </linearGradient>
        </defs>
    </svg>
    <EmptyText>
        {t('emptyTransactions.title')}
    </EmptyText>
    <EmptyDescription>
        {t('emptyTransactions.text')}
    </EmptyDescription>
</EmptyContainer>


const EmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 16px;
`

const EmptyText = styled.div`
    color: #FFF;
    text-align: center;;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
`

const EmptyDescription = styled.div`
    color: #777;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
`