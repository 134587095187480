import styled from "styled-components";
import {CardResponse} from "../api/requests/cards";
import {Lock as LockIcon} from "./icons/lock";
import {t} from "i18next";


function formatNumber(num : number) {

    if(typeof num === 'undefined') {
        return 'max'
    }

    if (num >= 1e9) {
        return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'b';
    }
    if (num >= 1e6) {
        return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'm';
    }
    if (num >= 1e3) {
        return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'k';
    }
    return num.toString();
}



interface Props {
    cardData: CardResponse
    onSelect: (card: CardResponse) => void
}

export const Card = (props: Props) => {
    const {cardData} = props

    const isBought = cardData?.cur_level?.level > 0

    return (
        <Container
            onClick={() => props.onSelect(cardData)}
        >
            <ImgContainer>
                <Img
                    isBought={isBought}
                    src={cardData.card.art_url} alt=""/>
                <BlurOverlay/>
                {!isBought &&
                    <Lock/>
                }
            </ImgContainer>
            <Column>
                <UpColumn>
                    <Title>{cardData.card.title}</Title>
                    <InComePerHour>
                        <InComePerHourTitle>{t("cards.profitPerHour")}:</InComePerHourTitle>
                        <InComePerHourValue>
                            <img src="/images/icon-coin.png" style={{width: 11, height: 11}} alt=""/>

                            {isBought ? cardData.cur_level.coin_reward : cardData.next_level.coin_reward}
                        </InComePerHourValue>
                    </InComePerHour>
                </UpColumn>
                <Row>
                <Level>
                        lvl {isBought ? cardData.cur_level.level : cardData.next_level.level}
                    </Level>
                    <Price>
                        <img src="/images/icon-coin.png" style={{width: 18, height: 18}} alt=""/>
                        {isBought ? formatNumber(cardData?.next_level?.price) :  formatNumber(cardData?.next_level?.price)}
                    </Price>
                </Row>
            </Column>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 24px;
    background: #0B1925;
    //background: #fff;

    flex-direction: column;
    width: 100%;
    height: min-content;
    box-sizing: border-box;
    position: relative;
`


const ImgContainer = styled.div`
    position: relative;
    width: 100%;
    height: 127px;
    overflow: hidden;
    border-radius: 16px;
`;

const Img = styled.img<{ isBought: boolean }>`
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: ${props => props.isBought ? 'none' : 'grayscale(100%)'};
    image-rendering: crisp-edges;

`;

const BlurOverlay = styled.div`
    position: absolute;
    bottom:-2px;
    left: 0;
    width: 100%;
    height: 50%; /* Adjust the height to control how much of the bottom is blurred */
    background: linear-gradient(180deg, rgba(11, 24, 37, 0) 0%, #0B1825 89.5%);

`;

const Title = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    width: max-content;
`


const InComePerHourTitle = styled.div`
    color: #6e747d;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`


const InComePerHourValue = styled.div`
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 4px;
`

const InComePerHour = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 0px 12px 12px 12px;
    box-sizing: border-box;

`

const UpColumn = styled.div`
    display: flex;
    flex-direction: column;
`


const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    width: 100%;
`

const Level = styled.div`
    display: flex;
    width: 42px;
    padding: 7px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #102234;

    color: #FFF;

    font-size: 12px;
    font-style: italic;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
`


const Price = styled.div`
    display: flex;
    padding: 6px 4px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 12px;
    border: 1px solid #1C3146;
    gap: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    color: #fff;
    box-sizing: border-box;
    width: 70%;
`


const Lock = () => {
    return (
        <LockContainer>
            <LockIcon/>
        </LockContainer>
    )
}

const LockContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    border: 1px solid #384E63;
    position: absolute;
    top: 39px;
    right: 55px;
`

