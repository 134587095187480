import styled, {keyframes} from "styled-components";
import {DeckBG} from "./icons/deck-bg";
import {SDeckBG} from "./icons/s-deck-bg";
import {Button} from "./button";
import React, {useEffect} from "react";
import {ChestResult} from "../api/requests/model";
import {ShineBG} from "./icons/shine-bg";
import {Shine} from "./icons/shine";
import {animated, useSpring} from "@react-spring/web";
import {t} from "i18next";
import {ModalHead} from "./modal-head";

interface Props {
    type?: 'referral' | 'offline_reward_coin_cnt' | 'admin_profit_coin_cnt';
    coin_cnt?: number | null;
    chest: ChestResult | null;
    onRemoveChest?: (uid: string) => void;
    onRemoveCoin?: (type: string) => void;
}

const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const CongratWindow = (props: Props) => {
    const [size, setSize] = React.useState<'big' | 'small' | null>(null)
    const {animatedBalance} = useSpring({
        animatedBalance: props?.coin_cnt || 0,
        config: {tension: 170, friction: 26},
        delay: 10000
    });
    useEffect(() => {
        if (props?.chest?.chest) {
            setSize('big')
        } else {
            setSize('small')
        }
    }, [props?.chest?.chest])


    if (!size) return null


    return (

        <Absolute>
            <AnimatedRelative>
                <ModalHead variant={'red'}/>
                {(size === 'big') ?
                    <DeckBG/> : null
                }
                {(size === 'small') ?
                    <SDeckBG/>
                    : null
                }
                {(props.type === 'referral' || props.type === 'offline_reward_coin_cnt' || props.type === 'admin_profit_coin_cnt') &&

                    <Column>
                        <Text>
                            {props.type === 'admin_profit_coin_cnt' && t("admin_profit_coin_cnt")}
                            {props.type === 'referral' && t("referral_reward")}
                            {props.type === 'offline_reward_coin_cnt' && t("offline_reward_coin_cnt")}
                        </Text>

                        <Relative>
                            <Img src={'/images/deck.png'}/>
                            <Center>
                                <Row>
                                    <img
                                        src="/images/icon-coin.png"
                                        style={{
                                            width: 48,
                                            height: 48,
                                        }}
                                    />
                                    <CoinText>
                                        <animated.span>
                                            {animatedBalance.to((val) => numberWithCommas(Math.floor(val)))}
                                        </animated.span>
                                    </CoinText>
                                </Row>
                            </Center>
                        </Relative>
                        <Button
                            onClick={() => {
                                if (props.coin_cnt && props.onRemoveCoin) props.onRemoveCoin(props.type as string)
                            }}
                            width={'270px'}>
                            {t("get")}
                        </Button>
                    </Column>
                }

                {props?.chest?.chest &&
                    <Column2
                        style={{
                            overflow: 'hidden',

                        }}
                    >
                        {props?.chest.type !== 'initial' &&
                            <div style={{zIndex: 3, position: 'absolute'}}>
                                <Relative>
                                    <ShineBG style={{zIndex: 3}}/>
                                    <Shine style={{
                                        zIndex: 90,
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        right: '0',
                                        bottom: '0',
                                        margin: 'auto'
                                    }}/>

                                </Relative>
                            </div>
                        }
                        <Text style={{fontSize: '24px'}}>
                            {t('dugUpChest')}
                        </Text>
                        <Relative

                            style={{
                                width: '250px',
                                height: '250px',
                            }}
                        > <img src={props?.chest?.chest?.art_url}
                               style={{
                                   width: '250px',
                                   height: '250px',
                                   zIndex: 10000,
                                   position: 'absolute',
                                   top: '0',

                               }}/>
                        </Relative>
                        <Button
                            style={{zIndex: 100000}}
                            onClick={() => {
                                if (props.chest && props.onRemoveChest) props.onRemoveChest(props.chest?.uid)
                            }}
                            width={'270px'}>
                            {t("get")}
                        </Button>
                    </Column2>
                }
            </AnimatedRelative>
        </Absolute>

    )
}

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    position: absolute;
    bottom: 0;
    height: 114px;
    box-sizing: border-box;
`


const CoinText = styled.div`
    color: #FFF;
    text-align: center;

    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */

`

const Img = styled.img`
    width: 277px;
    height: 96px;
    top: 120px;
    left: 37px;
    padding: 1.73px 3.46px 3.5px 3.46px;

`

const Text = styled.div`
    color: #6E5C55;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    width: 100%;
    height: calc(100% - 0px);
    position: absolute;
    bottom: 0;
`

const Column2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    width: 98%;
    height: calc(100% - 31px);
    position: absolute;
    bottom: 0;
`

const Relative = styled.div`
    position: relative;
`


const Absolute = styled.div`
    z-index: 10000;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
`
const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;


const AnimatedRelative = styled(Relative)`
    animation: ${fadeIn} 0.3s ease-in-out;
`;