import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Menu} from "../components/menu";
import {useSendClicksOnEveryPage} from "../hooks/useSendClicksOnEveryPage";
import {useMeStore} from "../store/me-store";
import {FixedTopInfo} from "../components/fixed-top-info";
import {Card} from "../components/card";
import {MenuSwitch} from "../components/menu-switch";
import {CardResponse, useCardList} from "../api/requests/cards";
import {Button} from "../components/button";
import {BuyCardModal} from "../components/buy-card-modal";
import {Loader} from "../components/loader";
import {t} from "i18next";

export const Cards = () => {

    const {user} = useMeStore()
    const [activeTab, setActiveTab] = useState({label: t('cards.myCards'), value: 'my'})
    const [selectedCard, setSelectedCard] = useState<CardResponse | null>(null)
    const wrapperRef = useRef(null);
    const scrollContainerRef = useRef(null);

    const {data: cards, mutate, isLoading} = useCardList()

    useSendClicksOnEveryPage()
    useEffect(() => {
        //@ts-ignore
        graspil.push({event: 'Зашел в карточки'})
    }, []);


    if (isLoading || !user) {
        return <Loader/>
    }


    return (
        <Container>
            <Scroll ref={scrollContainerRef}>
                <FixedTopInfo user={user}/>
                <FixedBottomInfo>
                    <Wrapper ref={wrapperRef}>
                        <StickyWrapper>
                            <MenuSwitch
                                activeTab={activeTab.value}
                                onChange={(tab) => setActiveTab({label: tab, value: tab})}
                                tabs={[
                                    {label: t('cards.myCards'), value: 'my'},
                                    {label: t('cards.newCards'), value: 'new'}]
                                }
                            />
                        </StickyWrapper>
                        {(activeTab.value === 'my' && cards?.result && cards?.result?.filter((item) => item?.cur_level?.level).length === 0) &&
                            <EmptyList onClick={() => setActiveTab({label: t('cards.newCards'), value: 'new'})}/>
                        }
                        {(activeTab.value === 'my' && cards?.result && cards?.result?.filter((item) => item?.cur_level?.level).length > 0) &&
                            <GridList isFull={cards?.result?.filter((item) => item?.cur_level?.level)?.length <= 2}>
                                {cards?.result?.filter((item) => item?.cur_level?.level).map((item, i) => {
                                    return <Card
                                        onSelect={(card) => setSelectedCard(card)}
                                        cardData={item}
                                        key={i}/>
                                })}
                            </GridList>
                        }
                        {(activeTab.value === 'new' && cards?.result && cards?.result?.filter((item) => !item?.cur_level?.level).length === 0) &&
                            <EmptyNewList/>
                        }


                        {(activeTab.value === 'new' && cards?.result && cards?.result?.filter((item) => !item?.cur_level?.level).length > 0) &&
                            <GridList isFull={cards?.result?.filter((item) => !item?.cur_level?.level)?.length <= 2}>
                                {cards?.result?.filter((item) => !item?.cur_level?.level).map((item, i) => {
                                    return <Card
                                        onSelect={(card) => setSelectedCard(card)}
                                        cardData={item}
                                        key={i}
                                    />
                                })}
                            </GridList>
                        }
                    </Wrapper>
                </FixedBottomInfo>
                <Menu isAbsolute/>
            </Scroll>
            <BuyCardModal
                mutate={mutate}
                cardData={selectedCard}
                setCardData={setSelectedCard}
            />
        </Container>
    );
}


const StickyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    border-radius: 32px 32px 0px 0px;
    border-top: 1px solid #1C3146;
    background: #08121B;
    position: sticky;
    top: 0;
    padding: 16px;
    z-index: 1;
`

const FixedBottomInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    position: sticky;
    top: 0;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-image: url('/bg/main-bg.png');

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
    overflow: scroll
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    border-radius: 32px 32px 0px 0px;
    background: #08121B;
    margin-top: 30px;
    position: relative;
    border-right: 1px solid #1C3146;
    border-left: 1px solid #1C3146;
    scrollbar-width: none; /* Firefox */

`;

const GridList = styled.div<{ isFull?: boolean }>`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    width: 100%;
    height: ${props => props.isFull ? '100vh' : 'auto'};
    justify-items: center;
    padding-bottom: 90px;
    @media (max-height: 800px) {
        padding-bottom: 80px;
    }
`;

const Scroll = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    background-image: url('/bg/main-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    scrollbar-width: none; /* Firefox */
    -webkit-overflow-scrolling: auto;

`;


const EmptyList = ({onClick}: { onClick?: () => void }) => {
    return (
        <EmptyListContainer>
            <svg xmlns="http://www.w3.org/2000/svg" width="129" height="102" viewBox="0 0 129 102" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M34.8975 7.21542C34.8975 3.6722 37.7699 0.799805 41.3131 0.799805H87.5055C91.0487 0.799805 93.9211 3.6722 93.9211 7.21542V76.504C93.9211 80.0472 91.0487 82.9196 87.5055 82.9196H41.3131C37.7699 82.9196 34.8975 80.0472 34.8975 76.504V7.21542ZM68.0703 26.1815C66.9179 22.6351 61.9006 22.6351 60.7483 26.1815L59.2238 30.8732C58.7085 32.4593 57.2306 33.5331 55.5629 33.5331H50.6297C46.9007 33.5331 45.3503 38.3048 48.367 40.4966L52.3581 43.3964C53.7073 44.3766 54.2718 46.114 53.7565 47.7001L52.2321 52.392C51.0797 55.9384 55.1388 58.8875 58.1556 56.6957L62.1466 53.796C63.4958 52.8158 65.3227 52.8158 66.6719 53.796L70.663 56.6957C73.6797 58.8875 77.7389 55.9384 76.5865 52.3918L75.062 47.7001C74.5467 46.114 75.1113 44.3766 76.4605 43.3964L80.4515 40.4966C83.4683 38.3048 81.9179 33.5331 78.1888 33.5331H73.2556C71.588 33.5331 70.1101 32.4593 69.5947 30.8732L68.0703 26.1815Z"
                      fill="url(#paint0_linear_306_86084)"/>
                <path opacity="0.5"
                      d="M79.7693 88.0521L71.2832 91.5204L93.4416 100.577C96.7215 101.917 100.467 100.345 101.808 97.0652L128.021 32.9266C129.362 29.6467 127.789 25.9012 124.51 24.5607L99.0537 14.1567V76.504C99.0537 82.8819 93.8835 88.0521 87.5056 88.0521H79.7693Z"
                      fill="url(#paint1_linear_306_86084)"/>
                <path opacity="0.5"
                      d="M29.765 14.2305L4.49008 24.5605C1.21017 25.901 -0.361952 29.6465 0.978564 32.9265L27.1922 97.065C28.5327 100.345 32.2783 101.917 35.5582 100.577L66.2027 88.0519H41.3131C34.9352 88.0519 29.765 82.8817 29.765 76.5039V14.2305Z"
                      fill="url(#paint2_linear_306_86084)"/>
                <defs>
                    <linearGradient id="paint0_linear_306_86084" x1="64.5839" y1="0.799805" x2="64.5839" y2="75.0987"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFDA00"/>
                        <stop offset="1" stop-color="#F7B812"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_306_86084" x1="100.061" y1="14.1567" x2="100.061" y2="92.7792"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFDA00"/>
                        <stop offset="1" stop-color="#F7B812"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_306_86084" x1="33.5458" y1="14.2305" x2="33.5458" y2="92.786"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFDA00"/>
                        <stop offset="1" stop-color="#F7B812"/>
                    </linearGradient>
                </defs>
            </svg>
            <EmptyTitle>
                {t('cards.emptyTitle2')}
            </EmptyTitle>
            <EmptySubtitle>
                {t('cards.emptyDesc2')}
            </EmptySubtitle>

            <div style={{width: '60%'}}>
                <Button
                    onClick={onClick}
                    height={'34px'}>
                    {t('cards.buy')}
                </Button>
            </div>
        </EmptyListContainer>

    )
}

const EmptyNewList = () => {
    return (
        <EmptyListContainer>
            <svg xmlns="http://www.w3.org/2000/svg" width="95" height="128" viewBox="0 0 95 128" fill="none">
                <path opacity="0.5"
                      d="M38.8926 101.072C35.8874 101.072 33.0621 99.901 30.9374 97.776L11.0719 77.9105L1.81237 87.1698C1.10912 87.873 0.713867 88.8268 0.713867 89.8213C0.713867 90.816 1.10887 91.7695 1.81237 92.4728L36.2409 126.901C36.9731 127.634 37.9326 128 38.8924 128C39.8519 128 40.8119 127.634 41.5439 126.901L93.1869 75.2585C93.8901 74.5553 94.2854 73.6015 94.2854 72.607C94.2854 71.6125 93.8904 70.6588 93.1869 69.9555L83.9276 60.6963L46.8476 97.7763C44.7231 99.901 41.8979 101.072 38.8926 101.072Z"
                      fill="#3A70E2"/>
                <path
                    d="M58.7586 1.09837C57.2941 -0.366125 54.9196 -0.366125 53.4554 1.09837L1.81237 52.7414C1.10912 53.4446 0.713867 54.3984 0.713867 55.3929C0.713867 56.3874 1.10887 57.3411 1.81237 58.0444L36.2409 92.4729C36.9731 93.2051 37.9329 93.5714 38.8924 93.5714C39.8519 93.5714 40.8119 93.2054 41.5439 92.4729L93.1869 40.8301C93.8901 40.1269 94.2854 39.1731 94.2854 38.1786C94.2854 37.1839 93.8904 36.2304 93.1869 35.5271L58.7586 1.09837Z"
                    fill="#3A70E2"/>
            </svg>
            <EmptyTitle>
                {t('cards.emptyTitle')}
            </EmptyTitle>
            <EmptySubtitle>
                {t('cards.emptyDesc')}
            </EmptySubtitle>
        </EmptyListContainer>

    )
}


const EmptyListContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-top: 16px;
    height: calc(100vh * 0.6);
`

const EmptyTitle = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
`
const EmptySubtitle = styled.div`
    color: #777;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    width: 80%;
`

