import styled, {keyframes} from "styled-components";
 import {DeckBG} from "./icons/deck-bg";
import {SDeckBG} from "./icons/s-deck-bg";
 import {Button} from "./button";
import React, {useEffect} from "react";
import {Bag, ChestResult} from "../api/requests/model";
import {getTextByType} from "../pages/bag";
import {Ton} from "./icons/ton";
import {t} from "i18next";
import {ModalHead} from "./modal-head";

interface Props {
    chest: ChestResult | null;
    user_item: Bag | null;
    onGetPrize: () => Promise<any>;
    loading: boolean;
}


export const PrizeWindow = (props: Props) => {
    const [size, setSize] = React.useState<'big' | 'small' | null>(null)
     const {chest} = props

    useEffect(() => {
        if ((chest && chest?.user_item) || props?.user_item) {
            setSize('big')
        } else {
            setSize('small')
        }
    }, [chest])

    if (!size) return null

    return (

        <Absolute>
            <AnimatedRelative>
                <ModalHead variant={'red'}/>
                {(size === 'big') ?
                    <DeckBG/> : null
                }
                {(size === 'small') ?
                    <SDeckBG/>
                    : null
                }
                <Column>
                    {props?.user_item &&
                        <>
                            <Text
                                style={{
                                    marginBottom: 20
                                }}
                            >
                                {t('prizeWindow.yourReward5')}
                            </Text>

                        </>
                    }
                    {!props?.user_item &&
                        <>
                            <Text>
                                {t('prizeWindow.yourReward')}
                            </Text>
                            <Relative>
                                <Img src={'/images/deck.png'}/>
                                <Center>
                                    <Row>
                                        {props?.chest?.reward_ton_amount ? <Ton

                                            style={{
                                                width: 44,
                                                height: 44,
                                            }}
                                            /> :

                                            <img
                                            src="/images/icon-coin.png"
                                            style={{
                                                width: 48,
                                                height: 48,
                                            }}
                                        /> }
                                            <CoinText>
                                        {props?.chest?.reward_ton_amount ? props?.chest?.reward_ton_amount :props.chest?.reward_coin_cnt || 0}
                                    </CoinText>
                                    </Row>
                                </Center>
                            </Relative>
                        </>
                    }

                    {props?.user_item?.item &&
                        <>
                            <Text>
                                {props.chest?.user_item?.item?.title}

                            </Text>
                            <Relative>
                                <Row2>
                                    <img
                                        style={{
                                            width: 133,
                                            height: 133,
                                        }}
                                        src={props.chest?.user_item?.item?.art_url}/>
                                    <Modifier>
                                        {`${getTextByType(props.chest?.user_item?.item?.modifier?.name || '')} + ${props.chest?.user_item?.item?.modifier.name != 'tap_to_coin' ? ` ${Math.round(props?.chest?.user_item?.item?.modifier?.value!! * 100)} % ` : props.chest?.user_item?.item?.modifier?.value}`}
                                    </Modifier>
                                </Row2>
                            </Relative>
                        </>
                    }
                    {chest?.user_item?.item &&
                        <>
                            <Text>
                                {props.chest?.user_item?.item?.title}
                            </Text>
                            <Relative>
                                <Row2>
                                    <img
                                        style={{
                                            width: 133,
                                            height: 133,
                                        }}
                                        src={props.chest?.user_item?.item?.art_url}/>
                                    <Modifier>
                                        {`${getTextByType(props.chest?.user_item?.item?.modifier?.name || '')} + ${props.chest?.user_item?.item?.modifier.name != 'tap_to_coin' ? ` ${Math.round(props?.chest?.user_item?.item?.modifier?.value!! * 100)} % ` : props.chest?.user_item?.item?.modifier?.value}`}
                                    </Modifier>
                                </Row2>
                            </Relative>
                        </>
                    }
                    <Button
                        loading={props.loading}
                        onClick={async () => {
                             console.log('click')
                            try {
                                await props.onGetPrize()
                            } catch (e) {
                                console.log(e)
                            } finally {
                             }
                        }}
                        width={'270px'}>
                        {t('get')}
                    </Button>
                </Column>
            </AnimatedRelative>
        </Absolute>

    )
}

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;


const Modifier = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: left;
    color: rgba(157, 75, 29, 1);
    width: 55%;

`

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`
const Row2 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 40px;
    padding: 0 10px;
`


const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    position: absolute;
    bottom: 0;
    height: 114px;
`


const CoinText = styled.div`
    color: #FFF;
    text-align: center;

    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */

`

const Img = styled.img`
    width: 277px;
    height: 96px;
    top: 120px;
    left: 37px;
    padding: 1.73px 3.46px 3.5px 3.46px;

`

const Text = styled.div`
    color: #6E5C55;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    width: 100%;
    height: calc(100% - 0px);
    position: absolute;
    bottom: 0;
`


const Relative = styled.div`
    position: relative;
`

const Absolute = styled.div`
    z-index: 10000;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
`
const AnimatedRelative = styled(Relative)`
    animation: ${fadeIn} 0.3s ease-in-out;
`;