import styled from "styled-components";
import {t} from "i18next";


interface Props {
    text?: string;
    variant?: 'red' | 'gray';
}

export const ModalHead = (props: Props) => {
    return (
        <Absolute>
            <Relative>
                {props?.variant === 'gray' ?
                    <TextGray>
                        {props.text || t("congratulations")}
                    </TextGray> :
                    <Text>
                        {props.text || t("congratulations")}
                    </Text>
                }
                {props?.variant === 'gray' ?
                    <Img src={'/images/head-title-gray.png'}/> :
                    <Img src={'/images/head-title-red.png'}/>}
            </Relative>
        </Absolute>
    )
}

const Absolute = styled.div`
    position: absolute;
    top: -40px;
    left: -23px;
`

const Relative = styled.div`
    position: relative;
    width: 100%;
`

const Img = styled.img`
    width: 375px;
    height: 94px;
`

const Text = styled.div`
    text-align: center;
    text-shadow: #000 2px 2px 5px;
    font-size: 28px;
    font-style: normal;
    font-weight: bold;
    line-height: 32px; /* 133.333% */
    color: #F7A60E;
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);

`

const TextGray = styled.div`
    text-align: center;
    text-shadow: #000 2px 2px 5px;
    font-size: 28px;
    font-style: normal;
    font-weight: bold;
    line-height: 32px; /* 133.333% */
    color: darkgray;
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);
`