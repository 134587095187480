import React from 'react';
import styled from 'styled-components';

const SwitchContainer = styled.div<{ isOn: boolean }>`
  position: relative;
  width: 50px;
  height: 25px;
  background-color: ${({isOn}) => (isOn ? '#3A70E2' : '#ccc')};
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const SwitchHandle = styled.div<{ isOn: boolean }>`
  position: absolute;
  width: 23px;
  height: 23px;
  background-color: white;
  border-radius: 50%;
  top: 1px;
  left: ${({isOn}) => (isOn ? '26px' : '1px')};
  transition: left 0.3s;
`;


interface Props {
    isOn: boolean;
    onChange: (isOn: boolean) => void;
}

export const Switch = (props: Props) => {
    return (
        <SwitchContainer isOn={props?.isOn} onClick={() => props?.onChange(!props?.isOn)}>
            <SwitchHandle isOn={props?.isOn}/>
        </SwitchContainer>
    );
};

