import React, {useEffect, useMemo, useState} from 'react';
import './App.css';
import styled from "styled-components";

import {Menu} from "./components/menu";
import {useWidth} from "./hooks/useWidth";
import "./i18n.ts";

import {authHandler, diggerInfo, useUserInfo} from "./api/requests/user-info";
import {useToken} from "./hooks/useToken";
import {initNavigator, postEvent, SDKProvider} from '@telegram-apps/sdk-react';
import {useIntegration} from "@telegram-apps/react-router-integration";
import {GameAnalytics} from 'gameanalytics';


import {
    createBrowserRouter,
    Route,
    Router,
    RouterProvider,
    Routes,
    useNavigate
} from "react-router-dom";
import {tapHandler, unlockHandler} from "./api/requests/action";
import {CongratWindow} from "./components/congrat-window";
import {useChestStore} from "./store/chest-store";
import {useChestList} from "./api/requests/chest";
import {Loader} from "./components/loader";

import {MainChest} from "./components/main-chest";
import {useMeStore} from "./store/me-store";
import {Event, LEVEL_CNT} from "./constant";
import {retrieveLaunchParams} from '@telegram-apps/sdk';
import {useClickStore} from "./store/click-store";

import {useStateStore} from "./store/state-store";
import {PrizeWindow} from "./components/digged-window";
import {ChestResult, LevelUpReward} from "./api/requests/model";
import {useFromApiImagePreloader, useLocalImagePreloader} from "./hooks/useImagesPreload";
import {useImageLoadStore} from "./store/image-loaded-store";
import {useGallery} from "./api/requests/gallery";
import {getTap2CoinX2EventCnt} from "./utils/getTap2CoinX2EventCnt";
import {LevelUpgrade} from "./components/level-upgrade";
import {DigModal} from "./components/dig-windoq";

import {DesctopWindow} from "./components/desctop-window";
import {RegretWindow} from "./components/regret-window";
import {FixedTopInfo} from "./components/fixed-top-info";

import {useCooldownStore} from "./store/use-colddown-store";
import {TonConnectUIProvider} from "@tonconnect/ui-react";

import {BackButtonProvider} from "./components/back-button-provider";
import {Chests} from "./pages/chests";
import {Cards} from "./pages/cards";
import {ReferralPage} from "./pages/referral";
import {Bag} from "./pages/bag";
import {Settings} from "./pages/settings";
import {Rule} from "./pages/rules/rule";
import {EnterRule} from "./pages/rules/enter-rules";
import {Partner} from "./pages/partner/partner";
import {Digger} from "./pages/digger";
import {ChestsInfo} from "./pages/chests-info";
import {Profile} from "./pages/profile";
import {LeaderBord} from "./pages/leader-bord";
import {gameanalytics} from "gameanalytics";
import {Transactions} from "./pages/transactions";
import {StartInfo} from "./components/start-info";
import i18n from "i18next";
import {getChestsById} from "./utils/get-chest-by-id";


function App() {
    // TODO: TG
    const navigator = useMemo(() => initNavigator('app-navigation-state'), []);
    const [location, reactNavigator] = useIntegration(navigator);

    useEffect(() => {
        navigator.attach();
        return () => navigator.detach();
    }, [navigator]);


    useEffect(() => {
        GameAnalytics.setEnabledInfoLog(true);
        GameAnalytics.setEnabledVerboseLog(true);
        GameAnalytics.configureBuild('0.1.0');
        GameAnalytics.initialize('4fc9a236d0ae26548d1e46b9a0e5ace8', '91a5c28c6015eb1cb7c1fd6a3ef9f71512782ade')
        GameAnalytics.startSession()
    }, []);

    const routes = [
        {
            path: "/",
            element: <TgView/>
        },
        {
            path: "/chests",
            element: <Chests/>
        },
        {
            path: "/cards",
            element: <Cards/>
        },
        {
            path: "/referral",
            element: <ReferralPage/>
        },
        {
            path: "/bag",
            element: <Bag/>
        },
        {
            path: '/settings',
            element: <Settings/>
        },
        {
            path: '/rules/:id',
            element: <Rule/>
        },
        {
            path: '/enter-rules/:id',
            element: <EnterRule/>
        },
        {
            path: '/partner/:id',
            element: <Partner/>
        },
        {
            path: '/digger',
            element: <Digger/>
        },
        {
            path: '/chests-info',
            element: <ChestsInfo/>
        }, {
            path: '/profile',
            element: <Profile/>
        }, {
            path: '/leaderboard',
            element: <LeaderBord/>
        },
        {
            path: '/transactions',
            element: <Transactions/>
        }
    ];


    return (
        <SDKProvider>
            <TonConnectUIProvider manifestUrl={`${window.location.origin}/tonconnect-manifest.json`}>
                <Router navigator={reactNavigator} location={location}>
                    <Routes>
                        {routes.map((route, index) => (
                            <Route key={index} path={route.path}
                                   element={<BackButtonProvider>{route.element}</BackButtonProvider>}/>
                        ))}
                    </Routes>
                </Router>
            </TonConnectUIProvider>
        </SDKProvider>
    );

    //
    // const router = createBrowserRouter(routes);
    //
    // return (
    //     <TonConnectUIProvider manifestUrl={`https://diggergame.app/tonconnect-manifest.json`}>
    //
    //         <RouterProvider router={router}/>
    //     </TonConnectUIProvider>
    // )

}

const TgView = () => {
    const token = useToken()
    const width = useWidth();
    const nav = useNavigate()

    const {clicks, setClicks} = useClickStore();
    const {setActiveChest, setChests, chests, activeChestId} = useChestStore()
    const {user, setUser} = useMeStore()
    const {state, setState, setWasCalled, wasCalled} = useStateStore()
    const {data: me} = useUserInfo(!token)
    const {isLoading: isChestLoading, mutate: mutateChest} = useChestList(!token)

    const [prizedChest, setPrizedChest] = useState<ChestResult | null>(null)
    const [levelUpRewards, setLevelUpRewards] = useState<LevelUpReward[]>([])

    const [isFirstEnter, setIsFirstEnter] = useState(false)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [isGuessModalOpen, setIsGuessModalOpen] = useState(false)
    const [isStartInfoVisible, setIsStartInfoVisible] = useState(false)

    const {data: gallery} = useGallery()
    const {imagesPreloaded} = useLocalImagePreloader()

    const chest = useMemo(() => {
        return chests?.find(chest => chest.uid === activeChestId) || null
    }, [chests, activeChestId])
    const chestCount = chest?.current_tap_cnt || 0;
    const isChestOpen = chestCount >= (chest?.open_tap_cnt || 0)

    const {setStartTime, setColdDown, getRemainingTime} = useCooldownStore();

    const {imagesPreloaded: imagesPreloadedFromApi} = useFromApiImagePreloader({
        imageList: gallery?.result?.chests?.map((e) => e?.art_url) || []
    })

    useFromApiImagePreloader({
        imageList: !imagesPreloadedFromApi ? [] : gallery?.result?.items?.map((e) => e?.art_url) || [],
    })
    useFromApiImagePreloader({
        imageList: !imagesPreloadedFromApi ? [] : gallery?.result?.cards?.map((e) => e?.art_url) || [],
    })

    useFromApiImagePreloader({
        imageList: gallery?.result?.chests?.map((e) => e?.open_art_url) || []
    })

    const {loading: isImgLoading, setLoading: setImagesLoading} = useImageLoadStore()


    const TAP_TO_COIN = useMemo(() => {
        if (getTap2CoinX2EventCnt(user?.tap_to_coin_x2_chance || 0, chest?.current_tap_cnt || 0, 1) > 0) {
            return (user?.tap_to_coin || 1) * 2
        } else {
            return user?.tap_to_coin || 1
        }
    }, [user, chest])


    useEffect(() => {
        if (localStorage.getItem('start-info') === 'true') {
            setIsStartInfoVisible(false)
        } else {
            setIsStartInfoVisible(true)
        }
    }, [localStorage.getItem('start-info')])


    useEffect(() => {
        if (imagesPreloadedFromApi && imagesPreloaded) {
            setImagesLoading(false)
        }
    }, [imagesPreloaded, imagesPreloadedFromApi])


    const onRemoveChestFromState = (uid: string) => {
        if (!state?.rewards) return
        setState({
                ...state,
                rewards: {
                    ...state.rewards,
                    user_chests: state?.rewards.user_chests.filter(chest => chest.uid !== uid),
                    coins: state?.rewards.coins
                }
            }
        )
    }


    useEffect(() => {
        try {
            postEvent('web_app_expand')
            postEvent('web_app_set_header_color', {color: "#000000"});
            postEvent('web_app_set_background_color', {color: "#000000"});
            postEvent('web_app_setup_swipe_behavior', {allow_vertical_swipe: false});
            postEvent('web_app_setup_closing_behavior', {need_confirmation: true});
            postEvent('web_app_setup_back_button', {is_visible: false});
        } catch (e) {
        }
    }, []);


    const onRemoveCoinFromState = (type: string) => {
        if (!state?.rewards) return
        setUser((prev) => ({
            ...prev!!,
            coin_cnt: state?.rewards ? prev?.coin_cnt!! + (state?.rewards?.coins?.find(coin => coin?.type === type)?.amount || 0) : prev?.coin_cnt!!
        }))
        setState({
            ...state,
            rewards: {
                ...state.rewards,
                user_chests: state?.rewards.user_chests,
                coins: state?.rewards.coins.filter(coin => coin.type !== type)
            },
        })
    }

    const onRemoveUserItemFromState = () => {
        if (!state?.rewards) return
        setState({
            ...state,
            rewards: {
                ...state.rewards,
                user_chests: state?.rewards.user_chests,
                coins: state?.rewards.coins,
                user_item: undefined
            }
        })
    }

    const onRemoveX10FromState = () => {
        if (!state?.rewards) return
        setState({
            ...state,
            rewards: {
                ...state.rewards,
                ref_10cnt_reward: undefined
            }
        })
    }

    const onRemoveColdDownFromState = () => {
        if (!state) return
        setState({
            ...state,
            cooldown_sec: undefined
        })
    }

    useEffect(() => {
        if (me?.result && !user) setUser(() => me.result)
    }, [me])

    const isX2 = useMemo(() => {
        return getTap2CoinX2EventCnt(user?.tap_to_coin_x2_chance || 0, chest?.current_tap_cnt || 0, 1) > 0;
    }, [user, chest]);

    useEffect(() => {
        if (isChestOpen) {
            //@ts-ignore
            graspil.push({event: 'Открыл сундук'})

        }
    }, [isChestOpen]);


    useEffect(() => {
        mutateChest().then((value) => {
                setChests(() => value?.result || [])
                if (value?.result.find(chest => chest.type === 'initial')) {
                    if (value?.result.find(chest => chest.type === 'initial')?.cooldown_sec) {
                        const now = Date.now();
                        setStartTime(now);
                        setColdDown(value?.result.find(chest => chest.type === 'initial')?.cooldown_sec || 0);
                    }
                }
                if (!activeChestId) {
                    setActiveChest(value?.result[0]?.uid || null)
                }
            }
        )
    }, [token])

    useEffect(() => {
            async function sendClicks1(c: number) {
                await tapHandler({uid: chest?.uid as string, cnt: c}).then(res => {
                        if (res.result) {
                            if (res?.result?.user_chest) {
                                setTimeout(() => {
                                    if (res?.result?.user_chest.type == 'initial') {
                                        setChests((prev) =>
                                            prev?.map((item) => {
                                                    if (item.type === 'initial') {
                                                        return {
                                                            ...item,
                                                            cooldown_sec: res?.result?.user_chest?.cooldown_sec || 0,
                                                        }
                                                    }
                                                    return item;
                                                }
                                            ) || [])
                                        const now = Date.now();
                                        setStartTime(now);
                                        setColdDown(res?.result?.user_chest?.cooldown_sec);
                                    }
                                    setPrizedChest(res?.result?.user_chest)

                                }, 750)
                                setUser((prev) => ({
                                    ...prev!!,
                                    coin_cnt: res.result.coin_cnt || 0,
                                }))
                                GameAnalytics.addDesignEvent(Event.CHEST_OPENED, res?.result?.user_chest?.chest?.id)
                                GameAnalytics.addDesignEvent(Event.CHEST_OPEN_TON_REWARD, res?.result?.user_chest?.reward_ton_amount)

                                if (res?.result?.user_chest?.reward_ton_amount) {
                                    setUser((prev) => ({
                                        ...prev!!,
                                        ton_amount: (prev?.ton_amount || 0) + res?.result?.user_chest?.reward_ton_amount
                                    }))

                                }
                            }
                            if (res?.result?.levelup_rewards) {
                                GameAnalytics.addDesignEvent(`${Event.LEVEL_UP_LEVEL}:${res?.result?.level_info?.level}`)
                                setLevelUpRewards(res?.result?.levelup_rewards)
                            }
                            if (res?.result?.user_state) {
                                setUser((prev) => ({
                                    ...prev!!,
                                    tap_to_coin: res?.result?.user_state?.tap_to_coin || 0,
                                    coin_reward: res?.result?.user_state?.coin_reward || 0,
                                    tap_to_coin_x2_chance: res?.result?.user_state?.tap_to_coin_x2_chance || 0,
                                    level_info: {
                                        exp: res?.result?.level_info?.exp || 0,
                                        exp_to: res?.result?.level_info?.exp_to || 0,
                                        level: res?.result?.level_info?.level || 0
                                    }
                                }))
                            }
                        }
                    }
                )
            }

            if (clicks >= 10 && chest && (chest?.open_tap_cnt - chest?.current_tap_cnt > 10)) {
                let c = clicks
                setClicks(() => 0)
                sendClicks1(c).then()
            } else if (clicks >= 1 && chest && (chest?.open_tap_cnt - chest?.current_tap_cnt <= 10)) {
                let c = clicks
                setClicks(() => 0)
                sendClicks1(c).then()
            }
        },
        [clicks, chest]
    )


    // TODO: TG
    const {initDataRaw, platform} = retrieveLaunchParams();

    useEffect(() => {
        if (localStorage.getItem('token')) return;
        (async () => {
                if (token) return;
                const res = await authHandler(initDataRaw as string);
                localStorage.setItem('token', res.result.auth.token);
                if (res?.result?.state?.rewards?.user_chests?.length) {
                    GameAnalytics.addDesignEvent(`${Event.CHEST_DUG_UP}:initial`)
                    if (res?.result?.state?.rewards?.user_chests?.map((item: any) => item.type).includes('initial')) {
                        setIsFirstEnter(true)
                    }
                    setIsGuessModalOpen(true)
                }
                if (res.result.state) {
                    setState(res.result.state)
                }
                setWasCalled(true)
                if (res?.result?.state?.rewards?.user_chests?.map((item: any) => item.type).includes('initial')) {
                    nav('/enter-rules/1')
                }
            }
        )();
    }, [initDataRaw, token]);

    async function fillState(timeOut: number = 0) {
        if ((!wasCalled || state?.cooldown_sec) && user) {
            try {

                const data = await diggerInfo(initDataRaw as string);
                if (data?.result) {
                    if (data?.result?.rewards?.user_chests?.length) {
                        GameAnalytics.addDesignEvent(`${Event.CHEST_DUG_UP}:${getChestsById(data?.result?.rewards?.user_chests?.[0]?.chest?.id)}`)
                        setIsGuessModalOpen(true)
                    }
                    setTimeout(() => {
                        setState(data?.result)
                    }, timeOut)
                }
                setWasCalled(true)
            } catch (e) {
                console.log(e)
                setWasCalled(true)
            }
        }
    }

    useEffect(() => {
        fillState(750).then()
    }, [user]);


    const handleTouch = async (e: React.TouchEvent) => {
        e.preventDefault();
        await handleNewClick();
    };


    const handleNewClick = async () => {
        setClicks((prev) => prev + 1);
        if (!user) return
        const currentTapCount = chest?.current_tap_cnt || 0;
        const x2EventCount = getTap2CoinX2EventCnt(user?.tap_to_coin_x2_chance || 0, currentTapCount + 1, 1);
        const coinsToAdd = x2EventCount > 0 ? (user?.tap_to_coin || 1) * 2 : (user?.tap_to_coin || 1);

        setUser((prev) => ({
            ...user,
            coin_cnt: prev?.coin_cnt!! + coinsToAdd,
            level_info: {
                exp_to: user.level_info.exp_to,
                level: user.level_info.level,
                exp: (prev?.level_info?.exp || 0) + LEVEL_CNT
            }
        }))
        setChests((prev) =>
            prev && prev?.map((chest) => {
                if (chest.uid === activeChestId) {
                    return {
                        ...chest,
                        current_tap_cnt: chest?.current_tap_cnt!! + 1
                    }
                }
                return chest;
            }) || [])
    };

    if (['macos', 'tdesktop', 'weba', 'web', 'webk'].includes(platform)) {
        return <DesctopWindow/>
    }

    return (
        <Container>
            {/*TODO: TG*/}
            {(isChestLoading || !user || !width || !wasCalled || isImgLoading || isFirstEnter) &&
                <Loader/>
            }
            <FixedTopInfo isStartInfoVisible={isStartInfoVisible} user={user}/>
            <FixedBottomInfo>
                <MainChest
                    mutate={async () => {
                        await mutateChest().then((value) => {
                            if (value?.result) {
                                setChests(() => value?.result || [])
                            }
                        })
                    }}
                    total={chest?.open_tap_cnt || 0}
                    tapCount={TAP_TO_COIN}
                    chest={chest}
                    isX2={isX2}
                    coldDown={chest?.type === 'initial' ? getRemainingTime() : 0}
                    chestCount={chestCount}
                    isChestOpen={isChestOpen}
                    onTouchStart={(chest?.status !== 'locked' && !isChestOpen) ? handleTouch : undefined}
                />
            </FixedBottomInfo>
            <Menu/>
            {(!isGuessModalOpen && !isGuessModalOpen && !isFirstEnter && !prizedChest && !state?.cooldown_sec && !state?.rewards?.user_chests) &&
                <StartInfo
                    onClosed={() => {
                        setIsStartInfoVisible(false)
                        localStorage.setItem('start-info', 'true')
                    }}
                    isStartInfoVisible={isStartInfoVisible}/>
            }
            {!isGuessModalOpen && state?.rewards.user_item && (
                <PrizeWindow
                    loading={false}
                    onGetPrize={async () => onRemoveUserItemFromState()}
                    user_item={state.rewards.user_item}
                    chest={null}/>
            )}
            {(!isGuessModalOpen && state?.rewards?.ref_10cnt_reward) && (
                <CongratWindow
                    onRemoveChest={async (e) => {
                        mutateChest().then((value) => {
                            setChests(() => value?.result || [])
                            onRemoveX10FromState()
                        })
                    }}
                    chest={state?.rewards?.ref_10cnt_reward}
                />
            )}


            {prizedChest && (
                <PrizeWindow
                    loading={isButtonLoading}
                    chest={prizedChest}
                    onGetPrize={async () => {
                        setIsButtonLoading(true)
                        mutateChest().then((value) => {
                            setChests(() => value?.result || [])
                            setActiveChest(value?.result?.[0]?.uid || null)
                            setPrizedChest(null)
                            setIsButtonLoading(false)
                        })
                    }}
                    user_item={null}
                />
            )}

            {!isGuessModalOpen && !isFirstEnter && state?.rewards?.user_chests?.map((item, index) => (
                <CongratWindow
                    onRemoveChest={async (e) => {
                        mutateChest().then((value) => {
                            setChests(() => value?.result || [])
                            onRemoveChestFromState(e)
                        })
                    }}
                    chest={item} key={index}/>
            ))}

            {!isGuessModalOpen && !state?.cooldown_sec && !state?.rewards?.user_chests?.length && state?.rewards?.coins?.map((item, index) => (
                <CongratWindow
                    onRemoveCoin={onRemoveCoinFromState}
                    chest={null} key={index} type={item.type} coin_cnt={item?.amount}/>
            ))}


            {levelUpRewards?.length > 0 &&
                <LevelUpgrade
                    rewards={levelUpRewards}
                    onClick={() => {
                        setLevelUpRewards([])
                    }}/>
            }

            {isGuessModalOpen &&
                <DigModal
                    chest={state?.rewards?.user_chests?.[0] || null}
                    onClose={() => {
                        setIsGuessModalOpen(false)
                    }}/>
            }

            {!isGuessModalOpen && !state?.rewards?.user_chests?.length && state?.cooldown_sec &&
                <RegretWindow
                    mutate={async () => {
                        setTimeout(() => {
                            fillState()
                        }, 1000)
                    }}
                    onClose={onRemoveColdDownFromState}
                    coldown_sec={state?.cooldown_sec}
                />
            }


        </Container>
    );
}

const Container = styled.div`
    display: flex;
    height: 100vh;
    scrollbar-width: none;
    overflow: hidden;
    width: 100%;
    background-image: url('/bg/main-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
`;


const FixedBottomInfo = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
`

export default App;





