import React from "react";
// width="327" height="521"
export const DeckBG = () => {
    return (
        <Bg/>
    )
}



const Bg = ( props: any) => {
    return <svg
         xmlns="http://www.w3.org/2000/svg" width="327" height="521" viewBox="0 0 327 521" fill="none">
        <g filter="url(#filter0_d_5_4123)">
            <g filter="url(#filter1_i_5_4123)">
                <rect x="4" y="3" width="319" height="513" rx="33" fill="url(#paint0_linear_5_4123)"/>
            </g>
            <rect x="9" y="3" width="309" height="503" rx="33" fill="url(#paint1_linear_5_4123)"/>
            <mask id="mask0_5_4123"
                  style={{maskType: 'alpha'}}


                  maskUnits="userSpaceOnUse" x="9" y="3" width="309" height="503">
                <rect x="9" y="3" width="309" height="503" rx="33" fill="url(#paint2_linear_5_4123)"/>
            </mask>
            <g mask="url(#mask0_5_4123)">
                <g opacity="0.39" filter="url(#filter2_dif_5_4123)">
                    <path d="M103.545 42.1186C75.9482 43.2983 21.9203 26.3898 2 20V24.4237C3.29563 25.8983 3.16607 31.7966 5.88689 31.7966C9.28793 31.7966 28.7224 38.678 55.4448 42.1186C82.1672 45.5593 122.98 40.6441 178.854 38.1864C234.728 35.7288 300.481 46.7062 323.641 49C324.288 46.7062 324.515 42.1186 320.239 42.1186C314.895 42.1186 301.777 36.2203 258.049 31.7966C214.322 27.3729 138.041 40.6441 103.545 42.1186Z" fill="#E5AA88"/>
                </g>
                <g opacity="0.39" filter="url(#filter3_dif_5_4123)">
                    <path d="M2 297C21.9148 303.408 75.9278 320.366 103.517 319.183C112.152 318.813 124.899 318.103 139.843 317.315C146.584 316.93 153.871 316.55 161.804 316.2C203.737 314.17 254.074 312.428 281.779 315.239C325.495 319.676 325.009 301.437 330.352 301.437C334.627 301.437 335.209 325.099 320.638 332C317.237 319.183 217.663 313.735 161.805 316.2C161.804 316.2 161.804 316.2 161.804 316.2C154.14 316.571 146.756 316.951 139.843 317.315C99.126 319.642 78.3507 322.144 55.4301 319.183C28.715 315.732 9.28592 308.831 5.88582 308.831C3.16575 308.831 3.29527 302.915 2 301.437V297Z" fill="#E5AA88"/>
                </g>
                <g opacity="0.39" filter="url(#filter4_dif_5_4123)">
                    <path d="M103.545 476.119C75.9482 477.298 21.9203 460.39 2 454V458.424C3.29563 459.898 3.16607 465.797 5.88689 465.797C9.28793 465.797 28.7224 472.678 55.4448 476.119C82.1672 479.559 122.98 474.644 178.854 472.186C234.728 469.729 300.481 480.706 323.641 483C324.288 480.706 324.515 476.119 320.239 476.119C314.895 476.119 301.777 470.22 258.049 465.797C214.322 461.373 138.041 474.644 103.545 476.119Z" fill="#E5AA88"/>
                </g>
                <g opacity="0.39" filter="url(#filter5_dif_5_4123)">
                    <path d="M0.62851 97C24.5079 99.9561 118.602 112.361 147.688 111.575C148.105 111.561 148.522 111.546 148.939 111.531C183.351 110.031 264.776 98.5 308.397 103C352.017 107.5 315.182 110.5 320.513 110.5C324.779 110.5 325.522 118.667 324.876 121C306.458 112.5 230.364 108 174.627 110.5C166.101 110.882 157.495 111.23 148.939 111.531C148.536 111.549 148.119 111.563 147.688 111.575C100.753 113.205 55.4823 113.439 33.1017 110.5C6.4446 107 0.628503 106.5 -2.76422 106.5C-5.47839 106.5 -18.7585 114 -7.61096 106.5L0.62851 97Z" fill="#E5AA88"/>
                </g>
                <g opacity="0.39" filter="url(#filter6_dif_5_4123)">
                    <path d="M-0.184657 217.185C-0.184663 217.185 -0.184669 217.185 -0.184675 217.185L-0.1845 217.185C0.828232 216.091 1.95785 215.018 3.20701 214L-0.1845 217.185C-0.184553 217.185 -0.184605 217.185 -0.184657 217.185C19.6803 223.685 76.9495 233.2 104.47 232C119.539 231.343 145.395 229.63 173.36 228.291C175.408 228.191 177.478 228.094 179.569 228C179.971 227.982 180.373 227.965 180.776 227.948C214.554 226.44 250.255 225.645 273.079 228C316.685 232.5 318.624 221.5 323.953 221.5C328.217 221.5 324.599 236.667 323.953 239C301.025 236.683 236.231 225.661 180.776 227.948C178.292 228.059 175.817 228.174 173.36 228.291C120.815 230.863 82.1513 235.369 56.5032 232C29.8551 228.5 -2.60702 232 -5.9986 232C-8.34366 232 -6.63506 224.156 -0.184657 217.185Z" fill="#E5AA88"/>
                </g>
                <g opacity="0.39" filter="url(#filter7_dif_5_4123)">
                    <path d="M324 376C295.037 393.814 226.58 398.289 199.042 397.109C183.569 396.133 166.409 395.019 147.525 394.186C145.618 394.102 143.699 394.034 141.771 393.981C111.723 392.506 78.7208 391.639 53.5957 394.186C9.96176 398.61 8.38123 394.186 3.04819 394.186C-1.21823 394.186 2.40176 402.706 3.04819 405C25.3676 402.785 87.3318 392.47 141.771 393.981C163.907 395.067 184.441 396.484 199.042 397.109C229.254 399.015 253.036 400.394 270.67 398.119C297.335 394.678 316.728 387.797 320.121 387.797C322.836 387.797 322.707 381.898 324 380.424V376Z" fill="#E5AA88"/>
                </g>
                <g opacity="0.39" filter="url(#filter8_dif_5_4123)">
                    <path d="M222.77 180.119C250.281 181.298 304.142 164.39 324 158V162.424C322.708 163.898 322.838 169.797 320.125 169.797C316.735 169.797 297.361 176.678 270.721 180.119C244.082 183.559 203.396 178.644 147.696 176.186C91.995 173.729 26.4459 184.706 3.3584 187C2.71259 184.706 2.48654 180.119 6.74887 180.119C12.0768 180.119 25.1543 174.22 68.746 169.797C112.338 165.373 188.381 178.644 222.77 180.119Z" fill="#E5AA88"/>
                </g>
                <g opacity="0.39" filter="url(#filter9_dif_5_4123)">
                    <path d="M23.4774 41.8317C19.7003 36.8678 6.49593 34.7642 1.79416 39.7008C-0.26127 41.8589 -0.578533 44.9024 -1.38056 46.6607C-2.3831 48.8586 -8.71843 53.0088 -5.89198 56.5511C-3.06552 60.0933 1.06564 59.8013 1.92607 63.7582C2.78649 67.7151 2.68342 69.8887 9.27485 72.9679C15.8663 76.0471 14.9738 71.1866 17.9104 67.6704C20.8469 64.1542 27.9076 65.2308 30.9722 65.3293C34.0369 65.4279 31.9818 58.2467 26.8231 54.9481C21.6645 51.6495 28.1989 48.0366 23.4774 41.8317Z" fill="#E5AA88"/>
                </g>
                <g opacity="0.39" filter="url(#filter10_dif_5_4123)">
                    <path d="M16.6726 289.06C20.4874 286.406 22.5718 276.513 19.1192 272.762C17.6098 271.121 15.377 270.759 14.1121 270.082C12.5309 269.236 9.72422 264.281 6.99675 266.274C4.26928 268.267 4.31808 271.401 1.36395 271.891C-1.59018 272.382 -3.1898 272.216 -5.72747 277.073C-8.26515 281.929 -4.64296 281.451 -2.16708 283.811C0.308808 286.172 -0.770247 291.464 -0.96653 293.776C-1.16281 296.087 4.21839 294.824 6.86018 291.059C9.50197 287.294 11.9041 292.377 16.6726 289.06Z" fill="#E5AA88"/>
                </g>
                <g opacity="0.39" filter="url(#filter11_dif_5_4123)">
                    <path d="M29.2925 218.383C25.1327 217.156 14.4943 214.617 14.1217 209.504C13.9588 207.269 18.6916 205.509 19.0607 204.11C19.5221 202.361 17.2998 197.533 20.5981 196.863C23.8964 196.193 29.2785 198.384 31.642 196.525C34.0054 194.666 38.857 193.218 43.0568 190.277C47.2565 187.335 43.0285 198.44 43.0568 201.882C43.085 205.325 42.9024 205.588 44.7114 207.013C46.5204 208.439 42.3778 209.631 37.8533 209.033C33.3288 208.435 34.8477 220.022 29.2925 218.383Z" fill="#E5AA88"/>
                </g>
                <g opacity="0.39" filter="url(#filter12_dif_5_4123)">
                    <path d="M25.0881 447.704C30.7979 443.732 33.9179 428.925 28.75 423.309C26.4909 420.855 23.1489 420.312 21.2556 419.299C18.889 418.032 14.6881 410.617 10.6057 413.6C6.52338 416.582 6.59643 421.272 2.17481 422.007C-2.2468 422.741 -4.64104 422.493 -8.43932 429.762C-12.2376 437.031 -6.81607 436.315 -3.11027 439.848C0.595525 443.382 -1.01956 451.302 -1.31335 454.762C-1.60713 458.222 6.44722 456.332 10.4013 450.696C14.3555 445.061 17.9509 452.67 25.0881 447.704Z" fill="#E5AA88"/>
                </g>
                <g opacity="0.39" filter="url(#filter13_dif_5_4123)">
                    <path d="M300.641 283.129C300.928 290.135 311.659 300.684 319.107 299.242C322.363 298.611 324.594 296.036 326.449 294.952C328.767 293.597 337.207 293.941 336.882 288.856C336.557 283.771 332.594 281.34 334.331 277.16C336.068 272.98 337.549 271.06 333.486 263.939C329.424 256.818 327.139 261.845 322.212 263.141C317.284 264.438 311.515 258.841 308.776 256.75C306.037 254.658 303.335 262.566 305.948 268.951C308.561 275.337 300.282 274.372 300.641 283.129Z" fill="#E5AA88"/>
                </g>
                <g opacity="0.39" filter="url(#filter14_dif_5_4123)">
                    <path d="M314.549 128.015C310.206 129.39 305.418 138.072 307.588 142.607C308.536 144.59 310.53 145.585 311.525 146.591C312.768 147.849 313.987 153.325 317.119 152.252C320.251 151.18 321.101 148.222 324.017 148.627C326.932 149.031 328.387 149.656 332.16 145.837C335.933 142.019 332.394 141.407 330.743 138.464C329.093 135.522 331.62 130.867 332.466 128.753C333.312 126.639 327.896 126.249 324.337 129.012C320.779 131.774 319.977 126.296 314.549 128.015Z" fill="#E5AA88"/>
                </g>
            </g>
        </g>
        <defs>
            <filter id="filter0_d_5_4123" x="0.6" y="0.6" width="325.8" height="519.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1.7"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.168627 0 0 0 0 0.376471 0 0 0 0.57 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_4123"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_4123" result="shape"/>
            </filter>
            <filter id="filter1_i_5_4123" x="4" y="-1" width="319" height="517" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="-4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.4375 0 0 0 0 0.253906 0 0 0 0 0.109375 0 0 0 0.9 0"/>
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5_4123"/>
            </filter>
            <filter id="filter2_dif_5_4123" x="0" y="18.4" width="326" height="35.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_4123"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_4123" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5_4123"/>
                <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_5_4123"/>
            </filter>
            <filter id="filter3_dif_5_4123" x="0" y="295.4" width="335" height="41.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_4123"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_4123" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5_4123"/>
                <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_5_4123"/>
            </filter>
            <filter id="filter4_dif_5_4123" x="0" y="452.4" width="326" height="35.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_4123"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_4123" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5_4123"/>
                <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_5_4123"/>
            </filter>
            <filter id="filter5_dif_5_4123" x="-14" y="95.4" width="346" height="30.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_4123"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_4123" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5_4123"/>
                <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_5_4123"/>
            </filter>
            <filter id="filter6_dif_5_4123" x="-9" y="212.4" width="337" height="31.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_4123"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_4123" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5_4123"/>
                <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_5_4123"/>
            </filter>
            <filter id="filter7_dif_5_4123" x="-1" y="374.4" width="327" height="35.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_4123"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_4123" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5_4123"/>
                <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_5_4123"/>
            </filter>
            <filter id="filter8_dif_5_4123" x="1" y="156.4" width="325" height="35.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_4123"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_4123" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5_4123"/>
                <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_5_4123"/>
            </filter>
            <filter id="filter9_dif_5_4123" x="-8.6123" y="35.2885" width="43.0198" height="43.6579" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_4123"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_4123" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5_4123"/>
                <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_5_4123"/>
            </filter>
            <filter id="filter10_dif_5_4123" x="-8.59106" y="264.206" width="31.4641" height="35.7304" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_4123"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_4123" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5_4123"/>
                <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_5_4123"/>
            </filter>
            <filter id="filter11_dif_5_4123" x="12.1177" y="188.186" width="35.0281" height="35.3561" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_4123"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_4123" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5_4123"/>
                <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_5_4123"/>
            </filter>
            <filter id="filter12_dif_5_4123" x="-11.7322" y="411.298" width="45.1074" height="50.2013" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_4123"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_4123" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5_4123"/>
                <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_5_4123"/>
            </filter>
            <filter id="filter13_dif_5_4123" x="298.629" y="254.807" width="40.262" height="49.5688" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_4123"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_4123" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5_4123"/>
                <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_5_4123"/>
            </filter>
            <filter id="filter14_dif_5_4123" x="305.048" y="125.453" width="30.7893" height="31.9374" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.94061 0 0 0 0 0.808333 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_4123"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_4123" result="shape"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3"/>
                <feGaussianBlur stdDeviation="1"/>
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.626096 0 0 0 0 0.495 0 0 0 1 0"/>
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5_4123"/>
                <feGaussianBlur stdDeviation="0.8" result="effect3_foregroundBlur_5_4123"/>
            </filter>
            <linearGradient id="paint0_linear_5_4123" x1="163.5" y1="3" x2="163.5" y2="516" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F1C69E"/>
                <stop offset="1" stop-color="#CC9E7A"/>
            </linearGradient>
            <linearGradient id="paint1_linear_5_4123" x1="163.5" y1="3" x2="163.5" y2="506" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FEDBB1"/>
                <stop offset="1" stop-color="#D5AB87"/>
            </linearGradient>
            <linearGradient id="paint2_linear_5_4123" x1="163.5" y1="3" x2="163.5" y2="506" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FEDBB1"/>
                <stop offset="1" stop-color="#D5AB87"/>
            </linearGradient>
        </defs>
    </svg>
}