import {forwardRef, useEffect, useImperativeHandle, useMemo} from 'react';
import {Canvas, useFrame, useLoader, useThree} from '@react-three/fiber';
import {OrthographicCamera} from 'three';
import {BatchedRenderer, QuarksLoader, QuarksUtil} from 'three.quarks';
import {calcOrthographicCameraFrustum, removeObjectFromScene, setEffectCleaner} from "./effects/util";

export type EffectsCanvasRef = {
    showFireballEffect: (e: PointerEvent, scale?: number) => void;
}

const CanvasInset = forwardRef<EffectsCanvasRef, { width: number; height: number }>((props, ref) => {
    const {camera, scene} = useThree((s) => ({
        camera: s.camera as OrthographicCamera,
        scene: s.scene
    }));
    const batchRenderer = useMemo(() => new BatchedRenderer(), []);

     const object = useLoader(QuarksLoader, '/effects/blast.json');

    useEffect(() => {
        scene.add(batchRenderer);
        return () => {
            scene.remove(batchRenderer);
            batchRenderer.clear();
        };
    }, [scene, batchRenderer]);

    useEffect(() => {
        return () => scene.traverse(obj => removeObjectFromScene(scene, obj));
    }, [scene]);

    const showFireballEffect = (e: PointerEvent, scale: number = 0.2, sound: boolean = true) => {

        const normalizedX = (e.clientX / props.width) * 2 - 1;
        const normalizedY = -((e.clientY / props.height) * 2 - 1);
        const x = normalizedX * (camera.right - camera.left) / 2;
        const y = normalizedY * (camera.top - camera.bottom) / 2;

        const effect = object.clone(true);
        effect.renderOrder = 3
        effect.position.set(x, y, 0);
        effect.rotateZ(Math.random() * Math.PI * 2);
        effect.scale.set(scale, scale, scale);
        scene.add(effect);

        QuarksUtil.setAutoDestroy(effect, true);
        QuarksUtil.addToBatchRenderer(effect, batchRenderer);
        QuarksUtil.play(effect);



        setEffectCleaner(scene, effect);
    };

    useImperativeHandle(ref, () => ({
        showFireballEffect: showFireballEffect,
    }), [props.width, props.height, camera, object]);

    useFrame((_, delta) => {
        batchRenderer.update(delta);
    });

    useEffect(() => {
        const frustum = calcOrthographicCameraFrustum(4, props.width / props.height);
        camera.left = frustum.left;
        camera.right = frustum.right;
        camera.top = frustum.top;
        camera.bottom = frustum.bottom;
        camera.near = frustum.near;
        camera.lookAt(0, 0, 0);
        camera.updateProjectionMatrix();
    }, [camera, props.width, props.height]);

    return null;
});

export const EffectsCanvas = forwardRef<EffectsCanvasRef, { width: number; height: number }>((props, ref) => {
    return (
        <Canvas
             style={{position: 'absolute', top: 0, left: 0,}}
            orthographic
            camera={{...calcOrthographicCameraFrustum(4, props.width / props.height),}}
        >
            <CanvasInset ref={ref} width={props.width} height={props.height}/>
        </Canvas>
    );
});
