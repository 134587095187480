import styled from "styled-components";
import {useSendClicksOnEveryPage} from "../hooks/useSendClicksOnEveryPage";
import {Switch} from "../components/switch";
import {Menu} from "../components/menu";
import {IS_VIBRATION} from "../constant";
import {useEffect, useState} from "react";
import {t} from "i18next";

export const Settings = () => {
    useSendClicksOnEveryPage()
    const [isVibration, setIsVibration] = useState<boolean>(localStorage.getItem(IS_VIBRATION) === 'true')

    useEffect(() => {
        localStorage.setItem(IS_VIBRATION, isVibration.toString())
    }, [isVibration])

    return (
        <Container>
            <Column>
                <Title>
                    {t('settings')}
                </Title>
                <Row>
                    <Label>
                        {t('vibration')}
                    </Label>
                    <Switch isOn={isVibration} onChange={setIsVibration}/>
                </Row>
            </Column>
            <Menu/>
        </Container>
    )
}


const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-top: 25%;
`

const Label = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: white;

`


const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 20px;

`

const Title = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
`

const Container = styled.div`
    display: flex;
    height: 100vh;
    scrollbar-width: none;
    width: 100%;
    background-image: url('/bg/main-bg.png');
    background-size: cover;
    justify-content: space-between;
    
    align-items: center;
    flex-direction: column;
    background-color: #021127;
    position: relative;
`;
