export const LEVEL_CNT = 5

export const IS_VIBRATION = 'IS_VIBRATION'


export enum Event {
    CHEST_DUG_UP = 'chest:dug-up',
    CHEST_OPENED = 'chest:open',
    ONBOARDING_FINISH = 'onboarding:finish',
    TON_CONNECT = 'ton:connect',
    TON_WITHDRAW = 'ton:withdraw',
    BUTTON_SHARE = 'button:share',
    CHEST_OPEN_COIN_REWARD = 'chest:open:coin_reward',
    CHEST_OPEN_TON_REWARD = 'chest:open:ton_reward',
     LEVEL_UP_LEVEL = 'levelUp:level',
    ITEM_CRAFT_ARTIFACT = 'item:craft:artifact',
    ITEM_CRAFT_SUPER_ARTIFACT = 'item:craft:super_artifact',
    ITEM_SLOT_BUY = 'item_slot:buy',
    LEADERBOARD_LINK_GROUP = 'leaderboard:link_group',
    CARDS_BUY = 'cards:buy',
}

