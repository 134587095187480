import {ListItem} from "./list-item";
import styled from "styled-components";
import {Chest, ChestResult} from "../api/requests/model";


interface Props {
    data: ChestResult[]
    selectedChestId: string | null
    onSelect: (id: string) => void
 }

export const ChestList = (props: Props) => {


    return (
            <GridList>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
                    if (props?.data[item]?.chest) {
                        return <ListItem
                            isSelected={props.data[item]?.uid === props.selectedChestId}
                            onClick={() => {
                                props.onSelect(props.data[item]?.uid)
                            }}
                            key={item} data={props.data[item]}/>
                    } else {
                        return <ListItem key={item} data={undefined}/>
                    }
                })}
            </GridList>
     )
}




const GridList = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1px;
    padding: 1px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    justify-items: center; /* Center items horizontally within their columns */
    overflow: auto;
    padding-bottom: 70px;
`
