import {Info} from "./info";
import {Balance} from "./balance";
import React from "react";
import {User} from "../api/requests/model";
import styled from "styled-components";
import {Level} from "./level";
import {Header} from "./header";


interface Props {
    user: User | null
    isHeaderVisible?: boolean
    isStartInfoVisible?: boolean;
}

export const FixedTopInfo = ({user,isStartInfoVisible, isHeaderVisible = true}: Props) => {
    return (
        <SFixedTopInfo>
            {isHeaderVisible &&
                <Header user={user || null}/>
            }
            <Info tapProfit={user?.tap_to_coin || 1} hourProfit={user?.coin_reward || 0}/>
            <Level
                exp={user?.level_info?.exp || 0}
                expTo={user?.level_info?.exp_to || 0}
                level={user?.level_info?.level || 1}
            />
            <Balance
                isStartInfoVisible={isStartInfoVisible}
                balance={user?.coin_cnt || 0}/>
        </SFixedTopInfo>
    )
}

const SFixedTopInfo = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 264px;
    max-height: 264px;
    min-height: 264px;
    z-index: 2;

    @media (max-height: 650px) {
        height: 238px;
        min-height: 238px;
        max-height: 238px;
    }
`