import styled, {keyframes} from "styled-components";
import React from "react";
import {DeckBG} from "./icons/deck-bg";
import {Button} from "./button";
import {useUpgradeInfo} from "../api/requests/bag";
import {Item} from "../api/requests/model";
import {getTextByType} from "../pages/bag";
import {WoodSpinnerIcon} from "./icons/wood-spinner-icon";
import {ModalHead} from "./modal-head";
import {t} from "i18next";

interface Props {
    item: {
        data?: Item,
        type?: 'item' | 'artefact' | 'super_artefact'
    }
    onClose: () => void;
    onCraft: (itemId: number, to: 'artefact' | 'super_artefact') => void;
    loading: boolean;
}


export const CraftModal = (props: Props) => {

    const {
        data,
        isLoading
    } = useUpgradeInfo(props?.item?.data?.id, props.item?.type === 'item' ? 'artefact' : 'super_artefact')

    return (
        <Absolute>
            <AnimatedRelative>
                <ModalHead variant={'red'} text={t("craft.title")}/>
                <DeckBG/>
                {isLoading ? <Loading><Spinner/></Loading> :
                    <Column>
                        <Wrapper>
                            <Title>
                                {t("craft.description")}
                                {' '}
                                {props?.item?.type === 'item' ? t("craft.artefact") : t("craft.superArtefact")}!
                            </Title>
                            <ItemToCraftRow>
                                <ItemImage src={props?.item?.data?.art_url} alt={'img'}/>
                                <Plus left={'30%'}>
                                    +
                                </Plus>
                                <ItemImage src={props?.item?.data?.art_url} alt={'img'}/>
                                <Plus left={'70%'}>
                                    +
                                </Plus>
                                <ItemImage src={props?.item?.data?.art_url} alt={'img'}/>
                            </ItemToCraftRow>
                        </Wrapper>

                        <Wrapper>
                            <Title2>
                                {t("craft.artefact")}
                            </Title2>
                            <Row>
                                <MainImg src={data?.result?.art_url} alt={'img'}/>
                                {`${getTextByType(data?.result?.modifier?.name || '')} + ${data?.result?.modifier.name != 'tap_to_coin' ? ` ${Math.round(data?.result?.modifier?.value!! * 100)} % ` : data?.result?.modifier?.value}`}
                            </Row>
                        </Wrapper>
                        <Cost>
                            {t("craft.cost")}:
                            <CostValue>
                                <img src={'/images/icon-coin.png'} alt={'img'} style={{width: 16, height: 16}}/>
                                {data?.result?.upgrade_coin_cnt}
                            </CostValue>
                        </Cost>
                        <Wrapper style={{gap: 10}}>
                            <Button
                                height={'44px'}
                                loading={props.loading}
                                onClick={() => props.onCraft(props.item?.data?.id!!, props.item?.type === 'item' ? 'artefact' : 'super_artefact')}>
                                {t("craft.upgrade")}
                            </Button>
                            <Button
                                height={'44px'}
                                onClick={props.onClose}
                                type={'secondary'}>
                                {t("craft.cancel")}
                            </Button>
                        </Wrapper>
                    </Column>
                }
            </AnimatedRelative>
        </Absolute>
    )
}

const Absolute = styled.div`
    z-index: 10000;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);

`

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;


const Title = styled.div`
    color: #6E5C55;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

`

const MainImg = styled.img`
    width: 126px;
    height: 126px;
    object-fit: contain;
`

const Title2 = styled.div`
    color: #705044;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;

`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;

`

const Plus = styled.div<{ left?: string }>`
    display: flex;
    width: 41px;
    height: 50px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #6D1B01;
    font-size: 50px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    content: "+";
    position: absolute;
    transform: translate(-50%, -50%);
    left: ${props => props.left || '50%'};
    top: 50%;

`


const ItemToCraftRow = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;

`

const ItemImage = styled.img`
    width: 72px;
    object-fit: contain;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    position: absolute;
    top: 30px;
    padding: 16px;
    box-sizing: border-box;
    height: 450px;
`

const Cost = styled.div`
    color: #705044;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    justify-content: center;
`
const CostValue = styled.div`
    color: #9D4B1D;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 4px;
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #9D4B1D;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
`

const AnimatedRelative = styled.div`
    position: relative;
    animation: ${fadeIn} 0.3s ease-in-out;
`;


const spinner = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const Spinner = styled(WoodSpinnerIcon)`
    animation: ${spinner} 2s linear infinite;
`

const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 450px;
    position: absolute;
    top: 30px;
    padding: 16px;
    box-sizing: border-box;
`