import styled, {css} from "styled-components";
import React, {useEffect} from "react";
import {Level} from "../components/level";
import {Profile as ProfileIcon} from "../components/icons/profile";
import {TonConnectButton, useTonAddress, useTonConnectUI} from "@tonconnect/ui-react";
import {Ton} from "../components/icons/ton";
import {Menu} from "../components/menu";
import {useSendClicksOnEveryPage} from "../hooks/useSendClicksOnEveryPage";
import {Link} from "react-router-dom";
import {useBackendAuth} from "../hooks/use-ton-auth";
import {useUserInfo} from "../api/requests/user-info";
import {Withdraw} from "../withdraw";
import {t} from "i18next";

function beautifyTonAddress(address: string): string {
    if (!address) return "";

    const firstPart = address.slice(0, 9);
    const lastPart = address.slice(-9);

    return `${firstPart}...${lastPart}`;
}

const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const Profile = () => {
    const {data: user, mutate} = useUserInfo()
    const tonAddress = useTonAddress()
    const [tonUI] = useTonConnectUI()
    const [isWithdraw, setIsWithdraw] = React.useState(false)


    useSendClicksOnEveryPage()

    useBackendAuth({mutate})

    useEffect(() => {
        //@ts-ignore
        graspil.push({event: 'Зашел в профиль'})
    }, []);
    return (
        <Container>
            <Col>
                <ProfileIcon style={{
                    width: 80,
                    height: 80,
                }}/>
                <UserNickname>
                    {user?.result?.username || user?.result?.first_name || user?.result?.tg_id || 'User'}
                </UserNickname>
                <Level
                    exp={user?.result?.level_info?.exp || 0}
                    expTo={user?.result?.level_info?.exp_to || 0}
                    level={user?.result?.level_info?.level || 1}
                />
                <Row>
                    <Balance>
                        <img src="/images/icon-coin.png"
                             style={{
                                 width: 30,
                                 height: 30,
                             }}
                        />
                        {numberWithCommas(user?.result?.coin_cnt || 0)}
                    </Balance>
                    <Balance>
                        <Ton/>{user?.result?.ton_amount || 0}
                    </Balance>
                </Row>
                {user?.result?.ton_address ?
                    <AddressContainer>
                        <Address>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                 fill="none">
                                <g clip-path="url(#clip0_637_258962)">
                                    <path
                                        d="M15.3333 2.66667H2.66667H2C1.63267 2.66667 1.33333 2.36733 1.33333 2C1.33333 1.63267 1.63267 1.33333 2 1.33333H12V2H13.3333V0.666667C13.3333 0.298667 13.0347 0 12.6667 0H2C0.895333 0 0 0.895333 0 2V13.3333C0 14.806 1.194 16 2.66667 16H15.3333C15.7013 16 16 15.7013 16 15.3333V3.33333C16 2.96533 15.7013 2.66667 15.3333 2.66667ZM12 10.6667C11.2633 10.6667 10.6667 10.07 10.6667 9.33333C10.6667 8.59667 11.2633 8 12 8C12.7367 8 13.3333 8.59667 13.3333 9.33333C13.3333 10.07 12.7367 10.6667 12 10.6667Z"
                                        fill="white" fill-opacity="0.4"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_637_258962">
                                        <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            {beautifyTonAddress(user?.result?.ton_address || '')}
                        </Address>
                        <ChangeAddress
                            onClick={() => {
                                tonUI.openModal()
                            }
                            }
                        >
                            {t('change')}
                        </ChangeAddress>

                    </AddressContainer>
                    :
                    <TonConnectButtonWrapper $isConnected={!!tonAddress}>
                        <TonConnectButton
                            style={{
                                width: !!tonAddress ? 'unset' : '100%',
                                height: '52px !important',
                                fontSize: '38px !important',
                                fontWeight: 700,
                                float: 'right',
                            }}
                        />
                    </TonConnectButtonWrapper>
                }
                {user?.result?.ton_address &&
                    <Button  onClick={() => setIsWithdraw(true)}>
                        {t('withdraw')}
                    </Button>
                }
                <Link
                    style={{
                        textDecoration: 'none',
                        width: '100%',
                    }}
                    to={'/transactions'}>
                    <TonButton>
                        {t('transactionHistory')}
                    </TonButton>
                </Link>
                <Link
                    style={{
                        textDecoration: 'none',
                        width: '100%',
                    }}
                    to={'/settings'}>
                    <TonButton>
                        {t('settings')}
                    </TonButton>
                </Link>
            </Col>
            <Menu isAbsolute/>
            {isWithdraw &&
                <Withdraw balance={user?.result?.ton_amount || 0} onClose={async () => {
                    await mutate()
                    setIsWithdraw(false)
                }}/>
            }
        </Container>
    )
}


const Button = styled.div`
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #84CCFF;
    background: linear-gradient(180deg, #68B5EC 0%, #2588CF 95.97%);
    box-shadow: 0px 3px 0px 0px #1D689F;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
`


const AddressContainer = styled.div`
    display: flex;
    height: 40px;
    padding: 8px 10px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`

const Address = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
`

const TonConnectButtonWrapper = styled.div<{ $isConnected?: boolean }>`
    width: 100% !important;
    display: flex;
    justify-content: center;
    ${({$isConnected}) =>
            !$isConnected &&
            css`
                & button {
                    border-radius: 16px;
                    border: 1px solid #84CCFF;
                    background: linear-gradient(180deg, #68B5EC 0%, #2588CF 95.97%);
                    box-shadow: 0px 3px 0px 0px #1D689F;
                    display: flex;
                    padding: 12px;
                    justify-content: center;
                    align-items: center;
                    align-self: stretch;
                    width: 100% !important;
                    height: 52px !important;
                    color: #FFF;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                }

                & div {
                    color: #FFF;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px; /* 155.556% */
                    width: auto;
                }
            `}
`;


const ChangeAddress = styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
    background: linear-gradient(180deg, #83DA0F 0%, #1EB649 95.97%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`


const Container = styled.div`
    display: flex;
    height: 100vh;
    scrollbar-width: none;
    overflow: hidden;
    width: 100%;
    background-image: url('/bg/main-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 48px 16px;
`;


const UserNickname = styled.div`
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`


const TonButton = styled.div`
    border-radius: 16px;
    border: 1px solid #224A73;
    background: linear-gradient(180deg, #1C3F63 0%, #112C49 95.97%);
    box-shadow: 0px 3px 0px 0px #0C2135;
    display: flex;
    padding: 0 12px;
    height: 52px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
`

const Col = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
`


const Balance = styled.div`
    color: #FFF;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
`