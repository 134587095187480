import React from "react";

export const Lock2Icon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M14.5 20V14C14.5 8.47716 18.9772 4 24.5 4C30.0228 4 34.5 8.47716 34.5 14V20C37.8138 20 40.5 22.6862 40.5 26V38C40.5 41.3138 37.8138 44 34.5 44H14.5C11.1863 44 8.5 41.3138 8.5 38V26C8.5 22.6862 11.1863 20 14.5 20ZM30.5 20V14C30.5 10.6863 27.8138 8 24.5 8C21.1862 8 18.5 10.6863 18.5 14V20H30.5ZM24.5 28C25.6046 28 26.5 28.8954 26.5 30V34C26.5 35.1046 25.6046 36 24.5 36C23.3954 36 22.5 35.1046 22.5 34V30C22.5 28.8954 23.3954 28 24.5 28Z"
                  fill="white" fill-opacity="0.2"/>
        </svg>
    )
}