import { create } from 'zustand';
import { User } from "../api/requests/model";

type MeState = {
    user: User | null;
    setUser: (user: (prev: User | null) => User | null) => void;
};

export const useMeStore = create<MeState>((set) => ({
    user: null,
    setUser: (user) => set((state) => ({ user: user(state.user) })),
}));
