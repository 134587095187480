import styled from "styled-components";
import {ItemBox} from "./icons/item-box";

import React from "react";
import {useWidth} from "../hooks/useWidth";
import {ChestResult} from "../api/requests/model";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {getGradientsById} from "../utils/get-gradients-by-id";
import 'react-circular-progressbar/dist/styles.css';

interface Props {
    data: ChestResult | undefined;
    onClick?: () => void;
    isSelected?: boolean;

}

export const ListItem = (props: Props) => {

    const WIDTH = useWidth()

    return (
        <Relative onClick={props.onClick}
        >
            {props.isSelected &&
                <Border
                    style={{
                        width: WIDTH / 3,
                        height: WIDTH / 3,
                        position: 'absolute',

                    }}/>
            }
            <ItemBox
                style={{
                    width: WIDTH / 3,
                    height: WIDTH / 3,
                    borderWidth: '20px',
                }}
            />
            {props?.data &&
                <div style={{
                    zIndex: 1,
                    position: "absolute",
                    top: '7%',
                    left: '6%',
                }}>
                    <ChestItem
                        data={props.data}
                        width={WIDTH / 3.5}
                    />
                </div>
            }

        </Relative>
    )

}


const Relative = styled.div`
    position: relative;
`


interface ChestProps {
    data: ChestResult | null
    width: number

}

const ChestItem = (props: ChestProps) => {
    const width = props?.width

    return (
        <Relative
        >

            <Outline
                width={width}
            >
                <svg style={{height: 0, width: 0}}>
                    <defs>
                        <linearGradient id="gradientColor" gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor={getGradientsById(Number(props?.data?.chest?.id))[0]}/>
                            <stop offset="100%" stopColor={getGradientsById(Number(props?.data?.chest.id))[1]}/>
                        </linearGradient>
                    </defs>
                </svg>

                {}
                <CircularProgressbar
                    value={(props?.data?.current_tap_cnt || 0) / (props?.data?.open_tap_cnt || 0) * 100}
                    styles={buildStyles({
                            pathColor: 'url(#gradientColor)',
                            textColor: '#f88',
                            backgroundColor: '#2c334f',
                            trailColor: '#2c334f',

                        },
                    )}
                />
                {props?.data?.cooldown_sec  &&
                    <Cold>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M12.5 16C9.186 16 6.5 18.686 6.5 22H18.5C18.5 18.686 15.814 16 12.5 16Z"
                                  fill="white"/>
                            <path
                                d="M19.4998 22H17.4998V17C17.4998 15.322 16.6628 13.764 15.2608 12.833L14.0068 12L15.2608 11.167C16.6628 10.236 17.4998 8.678 17.4998 7V2H19.4998V7C19.4998 8.903 18.7308 10.695 17.3968 12C18.7308 13.305 19.4998 15.097 19.4998 17V22Z"
                                fill="white"/>
                            <path
                                d="M7.5 22H5.5V17C5.5 15.097 6.269 13.305 7.603 12C6.269 10.695 5.5 8.903 5.5 7V2H7.5V7C7.5 8.678 8.337 10.236 9.739 11.167L10.993 12L9.739 12.833C8.337 13.764 7.5 15.322 7.5 17V22Z"
                                fill="white"/>
                            <path d="M21.5 1H3.5V3H21.5V1Z" fill="white"/>
                            <path d="M21.5 21H3.5V23H21.5V21Z" fill="white"/>
                        </svg>
                    </Cold>
                }
                <Img
                    width={width * 0.85}
                    src={props?.data?.chest?.art_url}
                    alt={'chest'}/>


            </Outline>

        </Relative>
    )
}


const Cold = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: center;
    position: absolute;
    top: 50%;
    z-index: 20;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 50%;
`;


const Img = styled.img<{ width: number }>`
    width: ${(props) => props.width * 0.9}px;
    height: ${(props) => props.width * 0.9}px;
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 3;
`;


const Outline = styled.div<{ width: number }>`
    width: ${(props) => props.width}px;
    height: ${(props) => props.width}px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1C0E3A;
    border: 1px solid #A7C3F0;
`;


const Border = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="105" height="105" viewBox="0 0 105 105" fill="none">
            <path
                d="M101.891 93.7748C101.53 97.6413 97.967 101.2 94.1151 101.553C66.2815 103.698 39.053 103.698 11.2291 101.553C7.36257 101.191 3.80361 97.6283 3.45121 93.7763C1.30626 65.9428 1.30622 38.7144 3.45109 10.8906C3.81289 7.02403 7.37559 3.46506 11.2276 3.11265C39.0611 0.967698 66.2895 0.967662 94.1133 3.11252C97.9797 3.4743 101.539 7.03688 101.891 10.8888C104.036 38.7224 104.036 65.9509 101.891 93.7748Z"
                stroke="url(#paint0_linear_214_49407)" stroke-width="3"/>
            <defs>
                <linearGradient id="paint0_linear_214_49407" x1="52.6712" y1="0.00390625" x2="52.6712" y2="104.661"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#DDA35F"/>
                    <stop offset="0.185" stop-color="#F2EDB9"/>
                    <stop offset="1" stop-color="#693C1D"/>
                </linearGradient>
            </defs>
        </svg>
    )
}