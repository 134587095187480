import styled, {keyframes} from "styled-components";
import {Button} from "./button";
import {LevelUpReward} from "../api/requests/model";
import {useMeStore} from "../store/me-store";
import {t} from "i18next";

interface Props {
    onClick: () => void;
    rewards: LevelUpReward[]
}

export const LevelUpgrade = (props: Props) => {
    const {rewards} = props
    const {user} = useMeStore()

    return (
        <AnimatedContainer>

            <Relative>

                <svg xmlns="http://www.w3.org/2000/svg" width="99" height="98" viewBox="0 0 99 98" fill="none">
                    <path
                        d="M45.8864 3.82747C47.8555 1.76844 51.1445 1.76844 53.1136 3.82748L57.6805 8.60302C58.9715 9.95301 60.9074 10.4708 62.7 9.94558L69.0561 8.08327C71.7939 7.28112 74.6468 8.92856 75.3205 11.7007L76.8705 18.078C77.3129 19.8983 78.7358 21.3186 80.5569 21.7578L86.9671 23.3037C89.7479 23.9743 91.4003 26.8369 90.5903 29.5803L88.74 35.8465C88.2079 37.6483 88.7308 39.5963 90.0936 40.8894L94.8444 45.3974C96.9222 47.369 96.9222 50.6798 94.8444 52.6514L90.0936 57.1593C88.7308 58.4525 88.2079 60.4005 88.74 62.2023L90.5903 68.4684C91.4003 71.2118 89.7479 74.0744 86.9671 74.7451L80.5569 76.2909C78.7358 76.7301 77.3129 78.1504 76.8705 79.9708L75.3205 86.348C74.6468 89.1202 71.7939 90.7676 69.0561 89.9655L62.7 88.1032C60.9074 87.578 58.9715 88.0957 57.6805 89.4457L53.1136 94.2213C51.1445 96.2803 47.8555 96.2803 45.8864 94.2213L41.3195 89.4457C40.0285 88.0957 38.0926 87.578 36.3 88.1032L29.9439 89.9655C27.2062 90.7676 24.3532 89.1202 23.6795 86.348L22.1295 79.9708C21.6871 78.1504 20.2643 76.7301 18.4431 76.2909L12.0329 74.7451C9.25207 74.0744 7.59966 71.2118 8.40975 68.4684L10.2601 62.2023C10.7921 60.4005 10.2692 58.4525 8.90638 57.1593L4.15566 52.6514C2.07784 50.6797 2.07784 47.369 4.15566 45.3974L8.90639 40.8894C10.2692 39.5963 10.7921 37.6483 10.2601 35.8465L8.40975 29.5803C7.59966 26.8369 9.25207 23.9743 12.0329 23.3037L18.4431 21.7578C20.2643 21.3186 21.6871 19.8983 22.1295 18.078L23.6795 11.7007C24.3532 8.92855 27.2061 7.28112 29.9439 8.08327L36.3 9.94558C38.0926 10.4708 40.0285 9.95301 41.3195 8.60302L45.8864 3.82747Z"
                        fill="url(#paint0_linear_399_212309)"/>
                    <defs>
                        <linearGradient id="paint0_linear_399_212309" x1="49.7909" y1="0.0488281" x2="49.7909"
                                        y2="88.6713"
                                        gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFDA00"/>
                            <stop offset="1" stop-color="#F7B812"/>
                        </linearGradient>
                    </defs>
                </svg>
                <Level>
                    {user?.level_info.level}
                </Level>
            </Relative>

            <Title>
                {t('levelUp.title')} <br/>
                {t('levelUp.description')}
            </Title>
            <Divider/>
            <Desc>
                {t('levelUp.reward')}
            </Desc>
            <Benifit>
                {t("profit_per_tap")}<Span>+{rewards[0]?.amount}

                <img style={{
                        width: 24,
                        height: 24,
                    }}
                    src={'/images/icon-coin.png'} alt={'coin'}/>
                </Span>
            </Benifit>
            <Button onClick={props.onClick} >
                {t('levelUp.good')}
            </Button>
         </AnimatedContainer>
    )
}

const Divider = styled.div`
    width: 327px;
    height: 1px;
    background: #2B2B2B;
`
const Level = styled.div`
    color:  #FFF;
    text-align: center;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px; /* 123.077% */
    position: absolute;
    top: 14px;
    left: 50%;
    transform: translateX(-50%);
`

const Relative = styled.div`
    position: relative;
    
 
 `

const Benifit = styled.div`
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

const Desc = styled.div`
    color:   #777;
 
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
`


const Span = styled.span`
    display: flex;
    align-items: center;
    gap: 8px;
    `

const Title = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */

`

const Container = styled.div`
    position: absolute;
    z-index: 99999;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid #FDD205;
    border-left: 2px solid #FDD205;
    border-right: 2px solid #FDD205;
    border-radius: 20px 20px 0 0;
    height: 70%;
    background: #08121B;
    box-shadow: 0px -20px 40px 0px #07121C;
    width: 100%;
    box-sizing: border-box;
    padding: 32px 24px;

    @media (min-height: 800px) {
        padding: 20px 20px 98px 20px;
    }


`
const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;


const AnimatedContainer = styled(Container)`
    animation: ${fadeIn} 0.5s ease-in-out;
`;

